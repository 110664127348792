import React, { useEffect } from "react";
import { useCallback } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginUser, SocailAuth } from "../apiCalls/users";
import { setLoader } from "../redux/loaderSlice";
import { Form, Input, message } from "antd";
import { useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { setUser } from "../redux/userSlice";
// import "./register.css";
import "./login.css"
import {
  GoogleOAuthProvider,
  GoogleLogin,
  googleLogout,
} from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useLocale } from "antd/es/locale";
import { checkActivityAvailability } from "../apiCalls/activities";
const rules = [
  {
    required: true,
    message: "required",
  },
];
var clientId =
  "432429443335-no9l1o05qnh9a30qht4h5hok2m9eu49k.apps.googleusercontent.com";

export default function Login({ modalState }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()

  console.log(location);

  const [loginMessage, setLoginMessage] = useState("");
  const [isSuccess, setIsSucess] = useState("green");

  const handleError = () => {
    toast.error('Invalid Email or password');
  }
  const handleSuccess = () => {
    toast.success('Login successful Welcome back!');
  }

  const onFinish = async (values) => {
    // console.log(values, "login");
    try {
      dispatch(setLoader(true));
      const response = await LoginUser(values);
      dispatch(setLoader(false));
      console.log(response, " Response");
      if (response.success) {
        localStorage.setItem("token", JSON.stringify(response.token));
        dispatch(setUser(response.user));
        localStorage.setItem("user", JSON?.stringify(response?.user))
        handleSuccess();
        console.log(location?.state);

        if (location?.state) {
          try {
            const response = await checkActivityAvailability(
              location?.state?.activityId,
              location?.state?.quantity,
              location?.state?.date
            );

            if (response) {
              navigate("/securecheckout", {
                state: {
                  selectedActivity: location?.state?.selectedActivity,
                  selectedOption: location?.state?.selectedOption,
                  adults: location?.state?.adults,
                  infants: location?.state?.infants,
                  date: location?.state?.date,
                  selectedTime: location?.state?.selectedTime,
                  activityId: location?.state?.activityId,
                },
              });
              const debounce = setTimeout(() => {
                window.location.reload()
              }, 500);

              return () => clearTimeout(debounce)
            }
          } catch (error) {
            toast.error("error from backend")
          }
        } else {
          navigate("/")
          const debounce = setTimeout(() => {
            window.location.reload()
          }, 500);

          return () => clearTimeout(debounce)
        }


        // setTimeout(function () {
        //   window.location.href = "/";
        //   // redirect('/')
        // }, 6000); // Delay the redirect by 1 second (adjust the delay as needed)

      } else {
        handleError()
        // throw new Error(response.message);
        // setLoginMessage("Invalid Credentials!");
        // setIsSucess("red");
        // message.error("Invalid Credentials!");
      }
      // setLoginMessage('')
    } catch (error) {
      setLoginMessage("Invalid Credentials!");
      setIsSucess("red");
      message.error("Invalid Credentials!");
      console.log(error, "error");
    }
  };
  useEffect(() => {
    setLoginMessage("");
    if (localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  const handleGoogleSuccess = (response) => {
    // const data
    console.log(" Google login success = ", response);
  };

  const handleGoogleFailure = (error) => {
    console.log("Error");
    // console.error(' Google login error = ', error);
    if (error.error === "popup_closed_by_user") {
      // Handle the case where the user closed the popup
      console.log("Google login popup closed by user");
      // You can show a message to the user or take other actions here
    } else {
      // Handle other error cases
      console.error("Google login error:", error);
    }
  };




  const validateEmail = (rule, value, callback) => {
    // console.log(" Value Email ", value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      callback(); // Validation successful
    } else {
      callback("Invalid email address"); // Validation failed
    }
  };

  const validatePassword = (rule, value, callback) => {
    // console.log(" Value Password ", value);
    if (value.length >= 8) {
      callback(); // Validation successful
    } else {
      callback("Password length must be 8, 1 upppercase ,1 lowercase and 1 special character"); // Validation failed
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  return (
    <div className="Register pb-[100px] mt-[50px] font-nunito max-sm:m-0 max-sm:p-0 max-sm:w-full max-lg:w-full max-lg:m-0 max-lg:p-0">
      <div className="flex flex-row gap-5 ml-[-100px] max-sm:m-0 max-sm:p-0 max-sm:w-full max-lg:w-full max-lg:m-0 max-lg:p-0">
        <div className="register-form-image max-sm:hidden ">
          <img
            className="object-cover hidden md:block"
            alt=""
            src="/rectangle-10891@2x.png"
          />
        </div>
        <div className="mt-[-20px] md:mt-10 w-[60%] max-sm:w-full max-sm:mt-32 max-sm:m-0 max-sm:p-0 max-lg:w-full max-lg:m-0 max-lg:p-0 max-lg:ml-44">
          <img src="/LOGO_MAKE_MY_BUCKET.png" alt="logo" className="w-[195px] h-32 ml-24 my-3" />
          <h2 className="mr-32 max-sm:mr-3">Login</h2>
          <Form onFinish={onFinish} className="p-2">
            <label style={{ color: isSuccess }}>{loginMessage}</label>
            <div className="w-[70%] max-sm:w-full">
              <div className="email-div">
                <label>Email</label>
                <Form.Item
                  rules={[{ required: true, validator: validateEmail }]}
                  className="w-full "
                  name="email"
                >
                  <Input
                    type="email"
                    placeholder="Email"
                    className=""
                  />
                </Form.Item>
              </div>

              <div className="password-div">
                <label>Password</label>
                <Form.Item
                  rules={[{ required: true, validator: validatePassword }]}
                  name="password"
                >
                  <Input
                    type="password"
                    placeholder="Password"
                    className=""
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <p className="ml-20 hover:text-blue-500 cursor-pointer max-sm:ml-44 hover:underline" onClick={() => {
                navigate("/forgetpassword")
              }}>Forgot Password?</p>
            </div>
            <div className="-ml-36 max-sm:m-0">
              <button
                className="text-white rounded-lg py-3 bg-darkslateblue-100 w-80 max-sm:w-72 mt-3 font-semibold"
                typeof="submit"
              >
                Login
              </button>
            </div>
          </Form>
          {/* <div id="googleSigninDiv" className="mb-5"></div> */}
          <div className="w-[70%] flex justify-center items-center flex-col gap-4 max-sm:ml-16 max-sm:pb-4  max-lg:ml-24">

            <GoogleOAuthProvider clientId={clientId}>
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  const data = jwt_decode(credentialResponse.credential);
                  console.log(" Socail Data Success ", data);
                  const response = await SocailAuth(data);
                  console.log(response, response?.token, " Response");
                  if (response.success) {
                    console.log("Token Please ", response.token);
                    localStorage.setItem("token", response?.token);
                    // if (localStorage.getItem("token")) {
                    window.location.href = "/";


                    // }
                  } else {
                    // throw new Error(response.message);
                    message.error("Invalid Credentials!");
                    window.location.href = "/login";
                  }

                  // localStorage.setItem("token", data.email);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
            <span className="ml-36">
              Don't have an account?
              <Link to="/register">
                <span className="hover:text-blue-500 cursor-pointer ml-1 hover:underline">
                  Signup
                </span>
              </Link>
            </span>

          </div>

          <span></span>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
