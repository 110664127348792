import React from "react";
import "./company.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

export default function Company() {
  const navigate = useNavigate()
  return (
    <div className="Company mb-10 md:ml-24 ml-5 font-nunito max-lg:w-full max-lg:m-0 max-lg:p-0 max-lg:ml-10">
      <div className="company-image -mt-3 max-lg:hidden">
        <img src="/image8@2x.png" alt="lazy" className=" rounded w-[760px] h-[477px] object-cover ml-52" />
        {/* <LazyLoadImage className=" rounded w-[760px] h-[477px] object-cover ml-52" src="/image8@2x.png" lazy="lazy" /> */}
      </div>

      <div className=" company-card -z-10  px-[2rem] py-2 md:w-[40vw] w-screen flex flex-col items-start justify-start gap-[20px] max-lg:w-full max-lg:ml-10">
        <div className="w-[50%] ml-64 max-sm:ml-0 max-sm:w-full max-lg:w-full max-lg:mx-0">
          <h2 className=" text-[37px] max-lg:text-center">Our Company</h2>
          <div className=' bg-orange-600 p-[2px] w-48  mb-10 ml-5 max-lg:ml-72 max-lg:w-44' ><hr /></div>

          <div>
            <h1 className="text-lg max-lg:text-center">About Manipro IT Services (OPC) Pvt Ltd</h1>
          </div>

          <div className="my-5">
            <p>Welcome to Manipro IT Services (OPC) Pvt Ltd, a parent company of the Brand “Make My Bucket List” where we are dedicated to redefining your travel and Adventure experience through innovation and seamless service. Our company operates at the intersection of technology and Services, catering specifically to the Travel Day Trips and Adventure industry.</p>
          </div>
          <button type="button" className="rounded-md ml-12 md:ml-0 bg-darkslateblue-100 mb-4 shadow-[0px_2px_6px_rgba(0,_0,_0,_0.14)] box-border  h-[65.96px] flex flex-row py-4 px-10 items-center justify-center w-56 text-xl border-[1px] border-solid border-button-stroke max-sm:mt-10 max-sm:-ml-0 max-sm:px-5 max-sm:py-2 max-lg:justify-center max-lg:items-center max-lg:ml-64" >
            <button type="button" className="text-[25px] max-sm:text-xl cursor-pointer" onClick={() => navigate("/aboutus")}>About Us</button>
          </button>
          {/* <button onClick={()=>navigate("/aboutus")}>About us</button> */}
        </div>
      </div>
    </div>
  );
}
