import React, { useEffect, useState } from 'react'
import UpcomingBooking from './BookingType/UpcomingBooking'
import CompletedBooking from './BookingType/CompletedBooking'
import { getMyBookings } from '../../../apiCalls/booking'
import { message } from 'antd'

const Bookings = () => {
    const [menu, setMenu] = useState(0)
    const [upcomingBooking, setUpcomingBooking] = useState([]);
    const [historyBooking, setHistoryBooking] = useState([]);




    const getMyBooking = async () => {
        const token = JSON.parse(localStorage.getItem("token")) // Replace with how you store the authentication token

        try {
            const response = await getMyBookings(token);
            // console.log(response, "myresponse");
            if (response.success) {
                setHistoryBooking(response["history"]);
                setUpcomingBooking(response["upcoming"]);
                // console.log("my booking history", historyBooking);
                // console.log("my booking upcoming", upcomingBooking);
            } else {
                throw new Error(response.error);
            }
        } catch (error) {
            message.error(error.message);
        }
    };



    //   console.log(filterUpcoming);
    //   console.log(historyBooking, "completed");

    useEffect(() => {
        getMyBooking()
    }, [])
    return (
        <div className="w-full flex flex-col gap-4 h-96 -mt-28">
            <div className="w-full flex justify-center items-center">
                <h1 className="text-2xl">Bookings</h1>
            </div>
            <div className="w-full flex border-2">
                <div className={`w-[50%] flex justify-center items-center py-3 cursor-pointer ${menu === 0 ? "bg-[#FDA71A] text-white" : "text-black bg-white"} `}>
                    <h1 onClick={() => setMenu(0)} className='text-lg'>Upcoming Bookings</h1>
                </div>
                <div className={`w-[50%] flex justify-center items-center py-3 cursor-pointer ${menu === 1 ? "bg-[#FDA71A] text-white" : "text-black bg-white"} `}>
                    <h1 onClick={() => setMenu(1)} className='text-lg'>Completed Bookings</h1>
                </div>
            </div>
            {menu === 0 && <UpcomingBooking upcoming={upcomingBooking} />}
            {menu === 1 && <CompletedBooking historyBooking={historyBooking} />}

        </div>
    )
}

export default Bookings