import React, { useEffect } from "react";

// import "./company.css";
import "../Components/Company/company.css"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
export default function AboutUs() {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="font-nunito w-full">
      <div className=" mb-[-50px] md:mt-7  Poster w-full h-[400px] bg-[url(/public/image114@2x.png)] bg-cover bg-no-repeat bg-[top] font-nunito max-sm:bg-[left] max-sm:h-[100px] max-lg:h-[300px]" >
        <div className="font-semibold  [text-shadow:0px_2px_3px_rgba(0,_0,_0,_0.25)] max-sm:text-2xl">
          About us
        </div>
      </div>

      <br />
      <br />
      <div>
        {/* <div className="Company mb-10 md:ml-24 ml-5 font-nunito mt-3 py-10"> */}
        {/* <div className="mt-2 z-10">
            <LazyLoadImage className=" rounded w-[660px] h-[477px] object-cover ml-40" src="/image8@2x.png" lazy="lazy" />
          </div> */}

        {/* <div className=" company-card  py-2 md:w-full flex flex-col items-start justify-start">
            <div className="w-[50%] ml-64 max-sm:ml-0 max-sm:w-full">
              <h2 className=" text-[37px]">Our Company</h2>
              <div className=' bg-orange-600 p-[2px] w-48  mb-10 ml-5' ><hr /></div>

              <div>
                <h1 className="text-lg">About Manipro IT Services (OPC) Pvt Ltd</h1>
              </div>

              <div className="mt-5">
                <p>Welcome to Manipro IT Services (OPC) Pvt Ltd, a parent company of the Brand “Make My Bucket List” where we are dedicated to redefining your travel and Adventure experience through innovation and seamless service. Our company operates at the intersection of technology and Services, catering specifically to the Travel Day Trips and Adventure industry.</p>
              </div>

              <div className=" text-3xl  font-medium inline-block h-[158.85px]">
                Make My Bucket List is an adventure tourism company founded in 20YY. Our
                vision is to be the most recognised and respected adventure business
                in India and in all over the world.
              </div>
              <div className="rounded-md ml-12 md:ml-0 bg-darkslateblue-100 mb-4 shadow-[0px_2px_6px_rgba(0,_0,_0,_0.14)] box-border  h-[65.96px] flex flex-row py-4 px-10 items-center justify-center w-56 text-xl border-[1px] border-solid border-button-stroke max-sm:mt-10 max-sm:-ml-0 max-sm:px-5 max-sm:py-2 cursor-pointer">
                <div className=" text-[25px] max-sm:text-xl cursor-pointer" onClick={() => navigate("/aboutus")}>About Us</div>
              </div>
            </div>
          </div> */}
        {/* </div> */}
        <div className="w-full flex flex-col justify-start items-center px-32 mt-5 gap-5 max-sm:px-5 max-lg:px-10 mb-5">
          <div className="w-full flex flex-col justify-center items-start text-start">
            <div>
              <h1 className="text-xl">About Manipro IT Services (OPC) Pvt Ltd</h1>
            </div>

            <div className="mt-5 text-start">
              <p>Welcome to Manipro IT Services (OPC) Pvt Ltd, a parent company of the Brand “Make My Bucket List” where we are dedicated to redefining your travel and Adventure experience through innovation and seamless service. Our company operates at the intersection of technology and Services, catering specifically to the Travel Day Trips and Adventure industry.</p>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-start">
            <div>
              <h1 className="text-xl">Our Mission</h1>
            </div>
            <div>
              <p className="text-start">At Manipro IT Services (OPC) Pvt Ltd, our mission is clear: to make services like travel more accessible, enjoyable, and organized for every adventurer. We understand that planning trips and adventures can often feel overwhelming and complex. That's why we've made it our goal to simplify the process through our cutting-edge ticketing tool, "Make My Bucket List".</p>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-start">
            <div className="w-full flex flex-col justify-center items-start">

              <div>
                <h1 className="text-xl">Organising Un-organised</h1>
              </div>
              <div>
                <p className="text-start">As our tagline suggests, we are experts at organizing the seemingly unorganized aspects of travel. Whether you're planning a spontaneous day trip or embarking on a thrilling adventure, we're here to streamline the ticketing process and ensure your journey is as smooth as possible.</p>
              </div>

            </div>



          </div>
          <div className="w-full flex flex-col justify-center items-start">
            <div>
              <h1 className="text-xl">Make My Bucket List: Live Life to the Fullest</h1>

            </div>
            <div>
              <p className="text-start">"Make My Bucket List" isn't just a tool—it's a philosophy. We believe in living life to the fullest and experiencing everything our beautiful world has to offer. Through our platform, we empower travelers to create and accomplish their bucket list dreams with ease.</p>
            </div>
          </div>

          <div className="w-full flex flex-col justify-center items-start">
            <div>
              <h1 className="text-xl">Our Services</h1>
            </div>
            <div>
              <p className="text-start">Manipro IT Services (OPC) Pvt Ltd serves as a third-party ticketing tool, connecting adventure seekers with the best day trips and activities around the globe. Whether you're looking to explore cultural landmarks, embark on outdoor excursions, or discover hidden gems in your own backyard, our platform provides convenient access to a diverse range of experiences.</p>
            </div>
          </div>

          <div className="w-full flex flex-col justify-center items-start">
            <div>
              <h1 className="text-xl">Why Choose Us?</h1>
            </div>
            <div>
              <ul className="w-full flex flex-col justify-start items-start text-start gap-2 ml-5">
                <li className="list-disc">	<b>Expertise: </b>With years of experience in the travel industry, we understand the nuances of creating unforgettable journeys.</li>
                <li className="list-disc"> <b>Innovation: </b>We leverage cutting-edge technology to simplify ticketing and enhance your travel planning experience.</li>
                <li className="list-disc"><b>Customer Focus: </b> Your satisfaction is our priority. We strive to provide exceptional service and support every step of the way.</li>
              </ul>
            </div>
          </div>

          <div className="w-full flex flex-col justify-center items-start">
            <div>
              <h1 className="text-xl">Join Us on the Journey</h1>
            </div>
            <div>
              <p className="text-start">Whether you're a seasoned traveler or embarking on your first adventure, Manipro IT Services (OPC) Pvt Ltd is your trusted partner in exploring the world. Let us help you turn your travel aspirations into reality. Together, let's organize the un-organized and make every trip a memorable experience.</p>
            </div>
          </div>

          <div className="w-full flex flex-col justify-center items-start">
            <div>
              <h1 className="text-xl">Contact Us</h1>

            </div>
            <div className="w-full flex justify-start items-start flex-col">
              <p className="text-start">Ready to start your next adventure? Contact us today to learn more about how we can assist you in creating the trip of a lifetime. Join us as we make travel simpler, more organized, and truly unforgettable.</p>
              <span className="text-blue-500 hover:underline cursor-pointer text-start w-full" onClick={() => navigate("/contactus")}>contact us</span>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
