import { message } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { cancelBooking } from '../../../../apiCalls/booking'
import { Pagination, Stack } from '@mui/material'

const UpcomingBooking = ({ upcoming }) => {
    const navigate = useNavigate()
    const [count, setCount] = useState(5)
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 6


    // pagination filter count data 

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = upcoming.slice(indexOfFirstItem, indexOfLastItem)

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    // console.log(upcoming, "--upcoming page" );

    const handleCancelError = () => {
        toast.error('Canot cancel this booking now');
    }
    const handleCancelSuccess = () => {
        toast.success('successful Canceled!');
    }
    const cancelBookings = async (id) => {
        // const id = activity._id;
        console.log('booking id', id)
        const token = localStorage.getItem("token")
        try {
            if (window.confirm("Are you sure you want cancel booking?")) {
                // dispatch(setLoader(true))
                const response = await cancelBooking(id, token);
                console.log("response if it canceled", response.message)
                // dispatch(setLoader(false))
                if (response.success) {
                    message.success(response.message)
                    handleCancelSuccess()
                    navigate("/payment-cancel", { state: { cancellationData: response } })
                    console.log(response.success)
                }
                else {
                    console.log(response.error)
                    handleCancelError()

                }
            }
        } catch (error) {
            message.error(error.message)
        }
    }
    return (
        <div>

            <div className="w-full flex flex-col justify-center items-center shadow-lg p-2 border-2 max-sm:w-full rounded max-sm:mt-2 max-sm:px-0">
                <div className="w-full">
                    <h1 className="px-6 py-5 max-sm:px-2 text-lg text-start w-full">Upcoming  Booking</h1>
                </div>
                <div className="flex justify-center items-center w-full">
                    <div className="w-full text-xs bg-[#F2F2F2] ">
                        <div className="flex w-full py-2 px-5 rounded max-sm:px-1">
                            <div className="w-full bg-white p-2 border-e-2 rounded-s-lg flex flex-col gap-2">
                                <h1 className='text-lg'>Product</h1>
                                <p className="w-full line-clamp-2">{upcoming[0]?.activity?.name}</p>
                            </div>
                            <div className="w-full bg-white p-2 border-e-2 flex flex-col gap-2">
                                <div>
                                    <h1 className='text-lg'>Date</h1>
                                </div>
                                <p>{upcoming[0]?.createdAt?.split("T")[0]}</p>
                            </div>
                            <div className="w-full bg-white p-2 border-e-2 flex flex-col gap-2">
                                <div>
                                    <h1 className='text-lg'>Location</h1>
                                </div>
                                <p>{upcoming[0]?.pickupLocation}</p>
                            </div>
                            <div className="w-full bg-white p-2 border-e-2 flex flex-col gap-2">
                                <div>
                                    <h1 className='text-lg'>Booking Status</h1>
                                </div>
                                <p>{upcoming[0]?.status}</p>
                            </div>

                            <div className="w-full flex flex-col gap-2 bg-white p-2 rounded-e-lg">
                                <button type="button" className="bg-[#2152B1] text-white py-1 px-2 rounded" onClick={() => navigate(`/view-booking-detail/${upcoming[0]?._id}`)}>View & Manage</button>
                                <button type="button" className="bg-[#FF3333] text-white py-1 px-2 rounded" onClick={() => cancelBookings(upcoming[0]?._id)}>Cancel Bookings</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full text-xs mt-10">
                    <table className="w-full text-xs">
                        <thead className="w-full">
                            <tr>
                                {/* <th className="">Booking Ref Number</th> */}
                                <th >
                                    <div className='w-full flex gap-1 justify-center items-center'>
                                        <img
                                            className=" w-5 h-5 overflow-hidden shrink-0"
                                            alt=""
                                            src="/passport.svg"
                                        />
                                        <p>Product</p>
                                    </div>
                                </th>
                                <th >
                                    <div className='w-full flex gap-1 justify-center items-center'>
                                        <img
                                            className=" w-5 h-5 overflow-hidden shrink-0"
                                            alt=""
                                            src="/date4.svg"
                                        />
                                        <p>Date</p>
                                    </div>
                                </th>
                                <th>pickup location</th>
                                {/* <th>Check-Out Date</th> */}
                                <th>
                                    <div className='w-full flex gap-1 justify-center items-center'>
                                        <img
                                            className=" w-5 h-5 overflow-hidden shrink-0"
                                            alt=""
                                            src="/table.svg"
                                        />
                                        <p>Booking Status</p>
                                    </div>
                                </th>

                            </tr>
                        </thead>
                        <tbody className={`w-full ${count !== 5 && "overflow-y-auto max-h-60"}`}>
                            {
                                currentItems?.map((bookingProduct) => {
                                    return (
                                        <tr className="text-center my-1" key={bookingProduct?._id}>
                                            {/* <td>GR12346655</td> */}
                                            <td className="line-clamp-1">{bookingProduct?.activity?.name}</td>
                                            <td>{bookingProduct?.createdAt?.split("T")[0]}</td>
                                            <td>{bookingProduct?.pickupLocation}</td>
                                            <td>{bookingProduct?.status}</td>
                                            {/* <td>Completed</td> */}
                                            <td>
                                                <button className="bg-[#2152B1] text-white py-1 px-2 rounded" onClick={() => navigate(`/view-booking-detail/${bookingProduct?._id}`)}> View & Manage</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }


                            {/* <tr className="text-center">
                             
                                <td className="line-clamp-1">Aqua the Luxury Farmhouse Hotel, Goa..</td>
                                <td>12:00 PM 23 May</td>
                                <td>12:00 PM 24 May</td>
                                <td>Completed</td>
                                <td>
                                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded" > View & Manage</button>
                                </td>
                            </tr> */}
                            {/* <tr className="text-center">
                                <td>GR12346655</td>
                                <td className="line-clamp-1">Aqua the Luxury Farmhouse Hotel, Goa..</td>
                                <td>12:00 PM 23 May</td>
                                <td>12:00 PM 24 May</td>
                                <td>Completed</td>
                                <td>
                                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded" > View & Manage</button>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>GR12346655</td>
                                <td className="line-clamp-1">Aqua the Luxury Farmhouse Hotel, Goa..</td>
                                <td>12:00 PM 23 May</td>
                                <td>12:00 PM 24 May</td>
                                <td>Completed</td>
                                <td>
                                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded" > View & Manage</button>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>GR12346655</td>
                                <td className="line-clamp-1">Aqua the Luxury Farmhouse Hotel, Goa..</td>
                                <td>12:00 PM 23 May</td>
                                <td>12:00 PM 24 May</td>
                                <td>Completed</td>
                                <td>
                                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded" > View & Manage</button>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>GR12346655</td>
                                <td className="line-clamp-1">Aqua the Luxury Farmhouse Hotel, Goa..</td>
                                <td>12:00 PM 23 May</td>
                                <td>12:00 PM 24 May</td>
                                <td>Completed</td>
                                <td>
                                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded" > View & Manage</button>
                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
                <div className='w-full justify-center items-center flex py-2'>
                    <Stack spacing={2}>

                        <Pagination
                            count={Math.ceil(upcoming?.length / itemsPerPage)}
                            variant="outlined"
                            shape="rounded"
                            page={currentPage}
                            onChange={handlePageChange} />
                    </Stack>
                </div>

            </div>
            {/* <div className='w-full py-2 flex justify-start items-start'>
                {count === 5 ? <button className="bg-[#2152B1] text-white py-1 px-2 rounded ml-5" onClick={() => setCount(upcoming?.length)}>Show More</button> :
                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded ml-5" onClick={() => setCount(5)}>Show Less</button>}
            </div> */}
        </div>
    )
}

export default UpcomingBooking