import React from 'react'
import DrawerAppBar from '../Components/Navbar/DrawerAppBar'
import Footer from '../Components/Footer'
import { useLocation, useNavigate } from 'react-router-dom'

const BookingCancelPage = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const cancelledData = location?.state?.cancellationData
    console.log(location?.state);
    return (
        <div className='w-full'>
            <DrawerAppBar />
            <div className='font-nunito w-full px-20 py-2'>

                <div className='w-full flex justify-start items-center p-2 gap-5'>
                    <p>Cancellation Date</p>
                    <p>{cancelledData?.booking?.createdAt?.split("T")[0]}</p>
                </div>
                <div className='w-full justify-center items-start flex flex-col gap-5 p-2'>
                    <h1 className={`text-2xl ${cancelledData?.booking?.status?.toLowerCase() === "cancelled" && "text-red-700"}`}>Your Booking Was Cancelled!</h1>
                    <p>We'll send confirmation you Cancellation to <b className='text-lg'>{cancelledData?.booking?.contactDetails?.email}</b></p>

                    <p>Mon 5 Jun, 7:00</p>
                </div>
                <hr />
                <div className='w-full grid grid-cols-2 p-2'>
                    <div className='flex flex-col w-full justify-center items-start gap-3'>
                        <div className='w-full text-start'>
                            <h1 className='' style={{ fontSize: "20px" }}>Cancellation Summary</h1>
                        </div>
                        <div className='w-full grid grid-cols-2 justify-center items-start'>

                            <div className='w-full flex flex-col justify-start items-start'>
                                <p>Product Name</p>
                                <p>Category</p>
                                <p>Travel Date</p>
                                <p>Booking Id</p>

                            </div>
                            <div className='w-full flex flex-col justify-start items-start'>
                                <del className="text-green-600">{cancelledData?.booking?.activity?.name}</del>
                                <del className="text-green-600">{cancelledData?.booking?.activity?.category?.toString()}</del>
                                <del className="text-green-600">{cancelledData?.booking?.date?.split("T")[0]}</del>
                                <del className="text-green-600">{cancelledData?.booking?.razorpay_order_id}</del>

                            </div>
                        </div>
                        <div className='w-full text-start'>
                            <h1 style={{ fontSize: "20px" }}>Price Breakdown</h1>
                        </div>
                        <div className='w-full grid grid-cols-2 justify-center items-start'>

                            <div className='w-full flex flex-col justify-start items-start'>
                                <p>Total Price</p>
                                <p>Cancellation Fee</p>
                                <p>Refundable Amount</p>
                                {/* <p>Booking Id</p> */}

                            </div>
                            <div className='w-full flex flex-col justify-start items-start'>
                                <p>{cancelledData?.booking?.activity?.price}</p>
                                <p>Category</p>
                                <p>Travel Date</p>
                                {/* <p>Booking Id</p> */}

                            </div>
                        </div>
                        <div className='w-full justify-start items-center flex gap-5 my-5 font-semibold'>
                            <button className='bg-[#2152B1] px-5 py-2 rounded text-whitesmoke'
                                onClick={() => {
                                    navigate("/select", { state: { item: cancelledData.booking?.activity } })
                                }}
                            >Book again</button>
                             <p className='text-[#2152B1] cursor-pointer' onClick={() => navigate("/search?category=&location=&activity=")}>Explore Other Experience</p>
                        </div>
                    </div>
                    <div className='w-full justify-center items-center flex'>
                        <img src={cancelledData?.booking?.activity?.images[0]} alt='logo' className='w-96 h-48 rounded object-center object-cover' />
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default BookingCancelPage