// import { Input } from 'antd'
import React, { useState } from 'react'
// import { useDispatch } from 'react-redux';
import { Form, message, Input, Button } from "antd";
import { toast } from 'react-toastify';
import { changePassword } from '../../../apiCalls/users';

const ChangePassword = () => {
    const [dataPassword, setDataPassword] = React.useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });

    const [loader, setLoader] = useState(false)

    // const dispatch = useDispatch();

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setDataPassword((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePASSWORDNOTMUCHError = () => {
        toast.error('comfirmation password not much');
    }
    // handle old password not correct 
    const handleoldpasswordError = () => {
        toast.error('old password not correct');
    }

    // handle Password changed succces
    const handlepasswordSuccess = () => {
        toast.success('password changed successfully');
    }



    const handlePasswordSubmit = async (values) => {
        // take data to submit
        console.log(values);
        if (values.newPassword !== values.confirmPassword) {
            handlePASSWORDNOTMUCHError()
            // message.error("New password and confirm password do not match");
            return;
        }
        try {
            // dispatch(setLoader(true));
            const response = await changePassword(
                values.oldPassword,
                values.newPassword
            );
            // dispatch(setLoader(false));
            if (response.success) {
                // handlepasswordSuccess();
                toast.success(response?.data?.message)
                setTimeout(function () {
                    window.location.reload();
                    // redirect('/')
                }, 6000);
                // message.success(response.message);
            } else {

                throw new Error(response.error);
            }
        } catch (error) {

            // message.error(error.message);

            toast?.error(error?.response?.data?.message)

            // handleoldpasswordError()
            // dispatch(setLoader(false)); // Stop the loading state

            // redirect('/profile')
        }
    };
    return (
        <div>
            <h2>Change Password</h2>
            <Form onFinish={handlePasswordSubmit} className="form">
                <Form.Item noStyle name="oldPassword">
                    <div className="form-item">
                        <label>Old Password</label>
                        <Input
                            required
                            name="oldPassword"
                            type="password"
                            value={dataPassword.oldPassword}
                            onChange={handlePasswordChange}
                            placeholder="Old Password"
                        />
                    </div>

                </Form.Item>
                <Form.Item noStyle name="newPassword">
                    <div className="form-item">
                        <label>New Password</label>
                        <Input
                            required
                            name="newPassword"
                            type="password"
                            value={dataPassword.newPassword}
                            onChange={handlePasswordChange}
                            placeholder="New Password"
                        />
                    </div>
                </Form.Item>
                <Form.Item noStyle name="confirmPassword">
                    <div className="form-item">
                        <label>Confirm Passowrd</label>
                        <Input
                            required
                            name="confirmPassword"
                            type="password"
                            value={dataPassword.confirmPassword}
                            onChange={handlePasswordChange}
                            placeholder="Confirm Password"
                        />
                    </div>
                </Form.Item>
                <button className="rounded-lg w-60 bg-darkslateblue-100" type="submit">
                    Change Password
                </button>
            </Form>
        </div>
    )
}

export default ChangePassword