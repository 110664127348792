import React, { useEffect } from "react";
import "./searchheader.css";
import { useLocation } from "react-router-dom";

export default function SearchHeader({ item }) {
  const location = useLocation()
  console.log(location, "---search Header");
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div className="SearchHeader font-nunito max-sm:m-0 max-sm:p-0 max-sm:w-full max-lg:w-full max-lg:p-0 max-lg:m-0">

      <div className="w-full text-start max-sm:w-full">Home / India / {item?.location?.name && `${item?.location?.name} /`} {item?.category?.name && `${item?.category?.name} /`} </div>
      <h1 className="max-sm:text-lg max-lg:text-lg" style={location?.pathname === "/select" ? { display: "none" } : { display: "flex", fontSize:'40px' }}>India Tours, Activities & Excursions</h1>
      <h1 className="text-header font-nunito">
        {item?.name?.trim()}
      </h1>
      <p style={location?.pathname === "/select" ? { display: "flex" } : { display: "none" }}>
        <span className="flex flex-row justify-center items-center gap-3">
          {[0, 1, 2, 3, 4].map(() => (
            <img
              className="relative w-[15.55px] h-[15.64px]"
              alt=""
              src="/magicstar20.svg"
            />
          ))}
          {item?.rating}
        </span>
      </p>
      <div className={`tags max-sm:grid-cols-2 max-sm:w-full gap-5 max-lg:px-2`} style={location?.pathname === "/select" ? { display: "none" } : { width: "100%", display: "grid", gridTemplateColumns: "repeat(7, 1fr)", padding: "0px !important", margin: "0px !important" }}>
        <span className="cursor-pointer">Cruises & Sailing</span>
        <span className="cursor-pointer">Water Tours</span>
        <span className="cursor-pointer">City Tours</span>
        <span className="cursor-pointer">Half-day Tours</span>
        <span className="cursor-pointer">Full-day Tours</span>
        <span className="cursor-pointer">Safaris</span>
        <span className="cursor-pointer">Water Parks</span>
        <span className="cursor-pointer">Private and Luxury</span>
        <span className="cursor-pointer">Bus Tours</span>
        <span className="cursor-pointer">Trekking</span>
        <span className="w-full cursor-pointer">Nature and Wildlife Tours</span>

      </div>
    </div>
  );
}
