import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getAllActivity } from "../apiCalls/activities";

export default function SimilarActivity({ item }) {
    const [result, setResult] = useState([]);
    const navigate = useNavigate();

    // console.log('current item ID', item._id);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAllActivity();
                if (response.success) {
                    setResult(response.activity);
                    // console.log("this is the top activities", response.activity);
                } else {
                    throw new Error(response.message);
                }
            } catch (error) {
                message.error(error.message);
            }
        };
        fetchData();
    }, []);
    // console.log(result[1]);
    return (
        <div className="">
            <div className="">
                <h1 className=" md:text-[35px] md:px-12 text-[23px] pb-5 font-nunito">Similar Tours & Experiences</h1>
                <div className=" w-full grid grid-cols-4 py-5 px-20 gap-5 max-sm:p-0 max-sm:grid-cols-1 max-sm:w-80 max-sm:ml-5 text-start max-lg:grid-cols-2 max-lg:px-5">
                    {result.slice(0, 4).map((i, index) => {
                        if (i._id !== item._id) {
                            return (
                                <div className="flex flex-col font-nunito font-semibold w-full rounded items-start shadow-lg  gap-3" key={index}>
                                    <div
                                        onClick={() => {
                                            // console.log(i, "book");
                                            navigate("/select", {
                                                state: { item: { ...i } },
                                            });
                                        }}
                                        className=" mb-4"
                                    >
                                        <div className="w-full h-52">
                                            <img src={i.images[0]} alt="" className="w-full rounded-t-md h-full" />
                                        </div>
                                        {/* <h2 className="px-2">{i.name}</h2> */}
                                        <div className="w-full flex flex-col p-2">
                                            <h2 className="w-full text-xxl ">{i.name.length > 15 ? i.description.substring(0, 20) + "..." : i.name}</h2>

                                            <p className="">{i.location.name}</p>
                                            <p className="w-full">{i.description.length > 45 ? i.description.substring(0, 45) + "..." : i.description}</p>
                                            <p className="px-2 py-1 bg-orange-500 w-24 mt-5 text-center rounded-full">{i.duration} {i.durationType}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        }

                    })}
                </div>
            </div>
        </div>
    );

}
