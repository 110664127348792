import { useState } from "react"
// import Footer from "../../Components/Footer"
// import Navbar from "../../Components/Navbar"
// import ContactBanner from "../../assets/ContactBanner.jpeg"
// import ProfileImage from "../../assets/ProfileImage.jpeg"
import Bookings from "./Bookings/Bookings"
import Profile from "./Profile/Profile"
import { useNavigate } from "react-router-dom"
// import { LiaUserEditSolid } from "react-icons/lia";
import { useRef } from "react"
import DrawerAppBar from "../../Components/Navbar/DrawerAppBar"
import Footer from "../../Components/Footer"
import ChangePassword from "./ChangePassword/ChangePassword"
import { useDispatch } from "react-redux"
import { logout } from "../../redux/userSlice"


const Account = () => {
    const [menu, setMenu] = useState(0)
    const [userName, setUserName] = useState("")
    // const [displayImage, setDisplayImage] = useState("/LOGO_MAKE_MY_BUCKET.png")
    // const [image, setImage] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const fileRef = useRef()

    // const handleChangeImage = (e) => {
    //     e.preventDefault()

    //     const file = e.target.files[0]
    //     if (file) {
    //         const objectUrl = URL.createObjectURL(file)
    //         setDisplayImage(objectUrl)
    //     }
    //     setImage(file)
    // }




    // const handleClick = () => {
    //     fileRef.current.click()
    // }

    // console.log(displayImage);

    return (
        <div className="w-full font-nunito">
            {/* <Navbar /> */}
            <DrawerAppBar />
            <div className="font-nunito relative bg-red-500 ">
                <div className="w-full font-nunito h-96 relative max-sm:hidden">
                    <img src="/image90@2x.png" alt="contact-banner" className="w-full h-96 object-cover object-center" />
                    <h1 className="absolute flex justify-center items-center top-44 text-4xl text-white capitalize font-semibold w-full font-nunito">My account</h1>
                </div>
                <div className="w-full flex justify-center items-center absolute top-72 max-sm:top-4 max-lg:top-52">
                    <div className="w-[80%] h-[600px] max-sm:h-[950px] border-2 shadow-lg rounded-lg flex  justify-center items-center gap-6 my-10 px-10 bg-white max-sm:w-full max-sm:flex-col max-sm:px-2">
                        <div className="flex flex-col gap-5 h-96 justify-center items-center w-[30%] border-e-2 border-gray-500 px-5 max-lg:px-1  max-sm:border-0 max-sm:w-full">
                            <div className="flex flex-col gap-4 justify-center items-center w-full max-lg:mr-10">
                                {/* <div className="">
                                   
                                    <input type="file" name="" id="" className="hidden" ref={fileRef} onChange={handleChangeImage} />
                                    <p className="text-center text-2xl w-full mt-5 cursor-pointer text-[#910033]" onClick={handleClick}>Change Profile</p>

                                </div> */}
                                <h1 className="text-xl">{userName}</h1>
                            </div>
                            <div className="w-full flex justify-center items-center flex-col max-lg:mr-10">
                                <p className={`${menu === 0 ? "py-3 px-5 w-full text-white bg-[#FDA71A] rounded cursor-pointer" : "py-3 px-5 w-full  rounded cursor-pointer"}`} onClick={() => setMenu(0)}>Profile details</p>
                                <p className={`${menu === 1 ? "py-3 px-5 w-full text-white bg-[#FDA71A] rounded cursor-pointer" : "py-3 px-5 w-full  rounded cursor-pointer"}`} onClick={() => setMenu(1)}>Bookings</p>
                                <p className={`${menu === 2 ? "py-3 px-5 w-full text-white bg-[#FDA71A] rounded cursor-pointer" : "py-3 px-5 w-full  rounded cursor-pointer"}`} onClick={() => setMenu(2)}>Change Password</p>
                                <p className="py-3 px-5 w-full border-black text-[#FF3333] rounded cursor-pointer max-sm:hidden"
                                    onClick={() => {
                                        dispatch(logout())
                                        navigate("/")
                                        localStorage.clear()
                                    }}>Logout</p>
                            </div>
                        </div>
                        <div className="w-[70%] max-sm:w-full">
                            {menu === 1 ? <Bookings /> : null}
                            {menu === 0 ? <Profile setUserName={setUserName} /> : null}
                            {menu === 2 ? <ChangePassword /> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-[40%] max-sm:mt-[280%] max-lg:mt-[450px]">
                <Footer />
            </div>
        </div>
    )
}

export default Account