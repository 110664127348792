import React, { useEffect, useState } from "react";
import "./securecheckout.css";
import { useLocation, useNavigate } from "react-router-dom";
import DrawerAppBar from "../Components/Navbar/DrawerAppBar";
import Footer from "../Components/Footer";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { cancelTheBooking } from "../apiCalls/booking";


export default function SecurePayConfirm() {
  const location = useLocation()
  console.log(location.state)
  const navigate = useNavigate()
  const [cancelF, setCancelF] = useState(false)
  const { data, orderData } = location.state || {}

  console.log(data, "data");
  console.log(orderData, "orderData");

  console.log(orderData)
  const bookId = data._id;
  const handleCancelError = () => {
    toast.error('Canot cancel this booking now')
  }
  const handleCancelSuccess = () => {
    toast.success('successfuly Canceled!')
  }

  const ColoredLine = ({ color }) => (
    <hr
      className="line-break"
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
      }}
    />
  );
  const cancelBooking = async (e) => {
    e.preventDefault()
    try {
      if (window.confirm("Are you sure want to cancel booking?")) {
        const response = await cancelTheBooking(orderData?.booking?._id);
        console.log(response);

        if (response.success) {
          handleCancelSuccess()
          console.log('success');
          navigate(`/view-booking-detail/${orderData?.booking?._id}`, { state: { cancellationMoney: data.amount / 100 * (3 / 10), refundAmount: ((data.amount / 100)?.toFixed(2) - ((data.amount / 100)?.toFixed(2) * (3 / 10))) } })
        } else {
          throw new Error(response.message);
        }
      }
    } catch (error) {

      console.log(error.message, "error");
    }
  }
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = [
        "January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    const day = days[date.getUTCDay()];
    const dayOfMonth = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}, ${dayOfMonth} - ${month} - ${year}`;
}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className=" w-full">
      <DrawerAppBar />

      <div className={`w-full font-nunito flex justify-start items-start flex-col my-4 px-20 ${cancelF && "font-bold"}`}>
        <div className="w-full flex  justify-start items-start flex-col gap-5">
          <span className="ml-2">Booking date : {orderData?.booking?.createdAt?.split("T")[0]}</span>
          <h1 className=" text-[40px] ml-2">
            Thanks for your Booking{" "}
            <span style={{ color: "lightgreen" }}> Your Booking is Confirmed!</span>
          </h1>
          <h2 className="text-lg font-normal ml-2">
            We’ve sent the booking confirmation voucher & ticket to {" "}
            <b> {data?.contactDetails?.email}</b>
          </h2>
          <h5 className="ml-2 font-bold">{formatDate(data?.date?.split("T")[0])}</h5>
        </div>
        {/*  */}

        <div className="w-full grid grid-cols-3 p-2">
          <div className="w-full col-span-2">
            <div className="w-full flex justify-start items-start">
              <h1 className="text-[25px]">Booking Activity Details</h1>

            </div>
            <div className="flex flex-col justify-start items-start gap-2 mt-5">
              <div className="flex justify-start items-center gap-5 ml-1">
                <div className="font-semibold">Product / Activity / Tour : </div>
                <p className="ml-1">{orderData?.booking?.activity?.name}</p>
              </div>
              <div className="flex justify-start items-center gap-5 ml-1">

                <div className="font-semibold">Travel Date: </div>
                <p className="ml-24">{orderData?.booking?.date?.split("T")[0]}</p>
              </div>
              <div className="flex justify-start items-center gap-5 ml-1">

                <div className="font-semibold">Status: </div>
                <p className="ml-32">{orderData?.booking?.status}</p>
              </div>
              <div className="flex justify-start items-center gap-5 ml-1">

                <div className="font-semibold">Pickup Location: </div>
                <p className="ml-16">{orderData?.booking?.pickupLocation}</p>
              </div>
            </div>
          </div>
          <div className="col-span-1 w-full flex justify-center items-center">
            <img src={orderData?.booking?.activity?.images[0]} alt="activity-img" className="w-72 h-52 object-cover rounded-lg" />
          </div>
        </div>

        {/* <ColoredLine color="black" /> */}

        {/* optional 1 */}
        <div className="w-full flex justify-start items-start flex-col gap-2 p-2">
          <h1 className=" text-[25px] w-full text-start">Price-Breakdown</h1>
          <div className="flex w-full justify-start items-start gap-16">
            <div className="text-start">
              Total Price : <br />
              <p className={`${cancelF && "text-red-500"}`}>  Cancellation Fee : <br /></p>
              Refundable Amount : <br />
            </div>
            <div className="text-start">
              ₹{(data.totalPrice)?.toFixed(2)} <br />
              <p className={`${cancelF && "text-red-500"}`}>₹{(data?.totalPrice* 0.3).toFixed(2)} <br /></p>
              ₹{((data.totalPrice) - (data.totalPrice* 0.3)).toFixed(2)} <br />
            </div>

          </div>
          <div>
            <button className={`cancel-booking ${cancelF && "hidden"}`} onClick={() => setCancelF(true)}>Cancel Booking</button>
          </div>
          {
            cancelF && <div className="w-full flex justify-start items-start flex-col">
              <h1 className=" text-[25px]">Reason For Cancelling</h1>

              <label for="dog-names">Select Reason</label>
              <div className=" flex justify-center mt-2">
                <select
                  style={{
                    width: "20rem",
                    // padding: "10px 40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    border: "1px solid black",
                    borderRadius: "5px",
                  }}
                  name="reason"
                  id="dog-names">
                  <option value="rigatoni">Changed of Plans</option>
                  <option value="dave">Personal Reason</option>
                  <option value="dave">Health issue</option>
                  <option value="dave">Weather concerns</option>
                  <option value="dave">Accommodation issues</option>
                  <option value="dave">Financial Issue</option>
                  {/* <option value="dave">Health issue</option>
                                        <option value="dave">Health issue</option> */}
                  <option value="dave">Others</option>
                  {/* <option value="pumpernickel">Pumpernickel</option>
                  <option value="reeses">Reeses</option> */}
                </select>
              </div>
              <br />
              <div className="w-full flex justify-start items-center gap-4 font-semibold">
                <button className="cancel-booking " onClick={cancelBooking}>Cancel Booking</button>
                <p className="text-blue-500 cursor-pointer hover:underline" onClick={() => setCancelF(false)}>Don’t Cancel this booking</p>
              </div>
            </div>
          }
          {/* <div className="footer-copyright">
            © 2023 GhumoRe, All rights reserved.
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
