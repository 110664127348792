import React, { useEffect, useState } from "react";
import "./securecheckout.css";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmBooking } from "../apiCalls/booking";
import useRazorpay from "react-razorpay";
import axiosInstance from "../apiCalls/axiosinstance";
import Visa from "../assets/4e04a91c20aa96ff1d0d869f345bf807.png"
import card from "../assets/99522665ac37423186aaa7e4968ce0e3.png"
import Paytm from "../assets/e3044c5c16641b8cbe0166ba1e5ef04d.png"
import { Card } from "antd";
import { toast } from "react-toastify";
// import razorPayLogo from "../../public/Razorpay_Logo.png"

export default function SecurePay() {
  const [selectOption, setSelectedOption] = useState("");
  const location = useLocation();
  // const [paymentId, setPaymentId] = useState()
  // const [signature, setSignature] = useState()
  // const [orderId, setOrderId] = useState()

  const [Razorpay] = useRazorpay()
  const { selectedActivity, data, selectedTime } =
    location.state || {};


  const navigate = useNavigate()

  console.log(selectedActivity);
  console.log(data, "--data");
  console.log(selectedTime);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  console.log(data._id, 'Booking Id')
  const bookId = data._id;
  const ColoredLine = ({ color }) => (
    <hr
      className="line-break"
      style={{
        color: color,
        backgroundColor: color,
        height: 1,
      }}
    />
  );
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Perform any necessary logic with the selected option
    try {


      // const response = await confirmBooking(bookId);
      // console.log(response);

      const paymentResponse = await axiosInstance.put(`/api/booking/pay/${bookId}`)
      const dataa = await paymentResponse?.data
      // console.log(data);
      // if(paymentResponse?.status == 200){
      //   return toast.success(data?.msg)
      // } else {
      //   return toast.error(data?.msg)
      // }

      console.log(data?.totalPrice);

      const options = {
        key: dataa?.key_id, // Enter the Key ID generated from the Dashboard
        amount: location?.state?.totalBill, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: dataa?.product_name,
        description: dataa?.description,
        image: "/LOGO_MAKE_MY_BUCKET.png",
        order_id: dataa?.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: async function (response) {
          console.log(response, "razor response");
          // setPaymentId(response.razorpay_payment_id);
          // setOrderId(response.razorpay_order_id);
          // setSignature(response.razorpay_signature);

          const responsePay = await axiosInstance.put("/api/booking/verifypayment", { order_id: response.razorpay_order_id, payment_id: response.razorpay_payment_id, signature: response.razorpay_signature })

          if (responsePay?.status == 200) {
            toast.success(responsePay?.data?.msg);
            navigate(`/view-booking-detail/${responsePay?.data?.booking?._id}`, {
              state: {
                data: data,
                orderData: responsePay?.data
              }
            })
          } else {
            toast?.error(response?.data?.message)
          }

          console.log(responsePay);
        },
        prefill: {
          name: data?.name,
          email: data?.email,
          contact: data?.contact,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        toast.error(response.error.code);
        toast.error(response.error.description);
        toast.error(response.error.source);
        toast.error(response.error.step);
        toast.error(response.error.reason);
        toast.error(response.error.metadata.order_id);
        toast.error(response.error.metadata.payment_id);
      });

      rzp1.open();



      // if (response.success) {
      //   navigate("/securepayconfirm", {
      //     state: {
      //       data: data
      //     }
      //   })
      // } else {
      //   throw new Error(response.message);
      // }
    } catch (error) {

      console.log(error.message, "error");
    }
    // navigate("/securepayconfirm", {
    //   state: {
    //     data: data
    //   }
    // })

  };

  const formattedDate = (date) => {
    const myDate = new Date(date);

    // Extracting date parts
    const day = myDate.getDate();
    const month = myDate.toLocaleString('default', { month: 'long' });
    const year = myDate.getFullYear();

    // Constructing formatted date string
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate
  }
  return (
    <div className="SecureCheckoutPage font-nunito">
      <div className="secure-checkout-form h-fit font-nunito">
        <h1 className="text-xl font-nunito">Secure Checkout</h1>

        <h2 className=" text-xl mt-1 font-nunito" >Pay with</h2>
        <form className="pay-form w-full font-nunito" onSubmit={handleSubmit}>
          <div className="pay-option">
            <input
              type="radio"
              id="option1"
              name="options"
              value="Visa"
              checked={selectOption === "Visa"}
              onChange={() => setSelectedOption("Visa")}
            />
            <div className="flex gap-3 justify-center items-center">
              <img src={card} alt="visa" className="w-6 h-6" />
              <img src={Visa} alt="card" className="w-6 h-6" />
              <label htmlFor="option1" className="font-semibold font-nunito">Credit / Debit Card</label>

            </div>
          </div>
          <br />
          <div className="pay-option">
            <input
              type="radio"
              id="option2"
              name="options"
              value="razor-pay"
              checked={selectOption === "razor-pay"}
              onChange={() => setSelectedOption("razor-pay")}
            />
            <div className="flex justify-center items-center flex-col gap-4 max-sm:w-full">
              <div className="w-full flex justify-center items-center gap-4">
                <img src="/Razorpay_Logo.png" alt="card" className=" bg-black-100 w-16 h-6 rounded-sm" />
                <label htmlFor="option2" className=" font-semibold font-nunito w-full">Razor pay</label>
              </div>


            </div>

          </div>
          <div className="w-full text-start ml-16">
            <ul>
              <li className="list-disc">
                <div>
                  <p>Net Banking</p>

                </div>
              </li>
              <li className="list-disc">
                <div>
                  <p>Upi Payment</p>

                </div>
              </li>
              <li className="list-disc">
                <div>
                  <p>Wallet</p>

                </div>
              </li>
              <li className="list-disc">
                <div>
                  <p>Pay later</p>

                </div>
              </li>
            </ul>
          </div>
          <div className="w-full text-start font-semibold mt-5 flex gap-1">
            <p>Before Booking Activity Please check Terms and Conditions</p>
            <p className="font-normal text-blue-500 hover:underline cursor-pointer" onClick={() => navigate("/termsandconditions")}>Click Here</p>
          </div>
          <br />
          <br />
          <input className="cursor-pointer w-96 max-sm:w-full border-2 font-nunito border-blue-600 bg-blue-600 text-white" type="submit" value="Book Now" />
        </form>
      </div>
      <div className="review-order-detail mt-5 font-nunito ml-20 max-sm:w-full max-sm:ml-5">
        <h2>Review order details</h2>
        <div className="review-order-card">
          <div className="review-order-img">
            <img src={selectedActivity.images[0]} alt="" className="object-cover" />
          </div>
          <div className="w-full text-start">
            <span className="font-bold text-start w-full">{selectedActivity?.name}</span>
            <div className="star">

              {selectedActivity.rating}
              {[0, 1, 2, 3, 4].map(() => (
                <img
                  className="relative w-[15.55px] h-[15.64px]"
                  alt=""
                  src="/magicstar20.svg"
                />
              ))}
            </div>
          </div>
        </div>
        <div className="font-bold flex flex-row mt-6"><img className="pr-2" src="/group3.svg" alt="" /> {data.quantity}</div>
        <div className="font-bold flex flex-row mt-2"><img className="pr-2" src="/date3.svg" alt="" /> {formattedDate(data.date)}, {selectedTime}</div>
        <div className="font-bold flex flex-row mt-2"><img className="pr-2" src="/crosscircle.svg" alt="" /> Non Refundable</div>
        <div className="mt-10 font-semibold flex justify-between items-center w-full">
          <span className="">Price: </span>
          <span className="">₹{data?.option?.unitPrice * data?.quantity}</span>

        </div>
        <div className="mt-2 font-semibold flex justify-between items-center w-full">
          <span className="">GST: </span>
          <span className="">₹{location?.state?.gst}</span>

        </div>
        <div className="review-order-cost py-10 ">
          <span className="font-bold">Total</span>
          <div className="review-order-cost-number w-full">
            <span className="text-lg font-bold text-right">
              ₹{(location?.state?.totalBill)}
            </span>
            <span className="text-right text-sm">(Including all taxes and booking fees)</span>
          </div>
        </div>

        {/* create a line under the review-order-cost div */}
        {/* <ColoredLine color="black" /> */}

        <div className="font-semibold font-nunito">
          <li className="text-start w-full">Lowest price guarantee</li>
          <li className="text-start w-full">Find it cheaper? We'll refund the difference</li>
          <li className="text-start w-full">Privacy protection</li>
          <li className="text-start w-full">We use SSL encryption to keep your data secure</li>
          <li className="text-start w-full">24/7 global support</li>
          <li className="text-start w-full">Get the answers you need, when you need them</li>
          <li className="text-start w-full">Give us a call</li>
          <li className="text-start w-full">We’d be happy to help you out with your booking</li>
          <li className="text-start w-full">Call now: 1800-0123-456-7890</li>
        </div>
      </div>
      <div className="footer-copyright">
        © 2023 GhumoRe, All rights reserved.
      </div>
    </div>
  );
}
