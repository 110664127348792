import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import "./register.css";
import { Form, message, Input, theme } from "antd";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoader } from "../redux/loaderSlice";
import { RegisterUser, authenticateWithGoogle } from "../apiCalls/users";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FacebookLogin from "react-facebook-login";
import jwt_decode from "jwt-decode";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import FacebookLoginComponent from "./FacebookComponent";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllCategories } from "../apiCalls/categories";
import { WidthFull } from "@mui/icons-material";
import axiosInstance from "../apiCalls/axiosinstance";
const rules = [
  {
    required: true,
    message: "Required",
  }
];

const validateInput = (rule, value, callback) => {
  // console.log(" Value Phone ", value);
  if (/^[a-zA-Z ]*$/.test(value)) {
    callback(); // Validation successful
  } else {
    callback('Only alphabets are allowed'); // Validation failed
  }
};

const validatePhoneNumber = (rule, value, callback) => {
  const val = value.split("+")[1].replace(/[\s-]+/g, '')
  console.log(" Validation Phone = ", value.split("+")[1].replace(/[\s-]+/g, ''));
  if (/^[0-9]{12}$/.test(val)) {
    callback(); // Validation successful
  } else {
    callback('Invalid phone number. It should be a 10-digit number.'); // Validation failed
  }
};
const validateEmail = (rule, value, callback) => {
  console.log(" Value Email ", value)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(value)) {
    callback(); // Validation successful
  } else {
    callback('Invalid email address'); // Validation failed
  }
};
// const validatePassword = (rule, value, callback) => {
//   console.log(" Value Password ", value);
//   if (value.length >= 8) {
//     callback(); // Validation successful
//   } else {
//     callback('Password must be at least 8 characters long'); // Validation failed
//   }
// };
const validatePassword = (rule, value, callback) => {
  console.log("Value Password ", value);
  if (value.length >= 8 && /[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value) && /[!@#$%^&*]/.test(value)) {
    callback(); // Validation successful
  } else {
    callback('Password must be at least 8 characters long and contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol (!@#$%^&*)'); // Validation failed
  }
};

export default function Register({ modalState }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("")
  const [phoneError, setPhoneError] = useState(false);
  const [categoriesList, setCategoriesList] = useState([])
  const [selectedInterest, setSelectedInterest] = useState([])
  const [verifyResponse, setVerifyResponse] = useState({})
  const [veerifyEmail, setVerifyEmail] = useState("")
  const [otp, setOtp] = useState("")
  const [otpVerifyresponse, setOtpVerifyResponse] = useState({})

  const handleChangeCategoryId = (catId) => {
    // e.preventDefault()

    if (selectedInterest?.includes(catId)) {
      setSelectedInterest(selectedInterest?.filter(id => id !== catId))
    } else {
      setSelectedInterest([...selectedInterest, catId])
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  console.log(selectedInterest);

  const onGoogleFinish = (values) => {

    // authenticateWithGoogle("email")
    // console.log("Encoded JWT ID token: " + values.credential);

    try {
      dispatch(setLoader(true));
      console.log(values);
      const userObject = jwt_decode(values.credential);
      console.log("object::: ", userObject);
      const response = authenticateWithGoogle(userObject.email);
      dispatch(setLoader(false));
      if (response.success) {
        message.success(response.message);
        navigate("/login");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error.message, "error");
    }
  };
  const [form] = Form.useForm();

  // const handleError = () => {
  //   toast.error('your phone number or email already exist please login');
  // }
  // const handleSuccess = () => {
  //   toast.success('Registered Succesfuly you can login In with your account now');
  // }

  const fetchCategories = async () => {
    try {
      const response = await fetchAllCategories()
      if (response) {
        setCategoriesList(response)
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  // console.log(categoriesList);

  const onFinish = async (values) => {

    if (!otpVerifyresponse.success) {
      toast.error("Email Must be verified")
    }
    if (!phone || phone.length < 8) {
      setPhoneError(true);
      return;
    }
    values.phoneNumber = phone;
    values.categories = selectedInterest
    // console.log(values, "finish");
    try {
      dispatch(setLoader(true));

      const response = await RegisterUser(values);
      console.log(response, "Sign Up message is going in here ");
      dispatch(setLoader(false));
      if (response.success) {

        message.success(response.message);
        // handleSuccess()
        navigate("/login");
      } else {
        form.resetFields();
        toast.error(response.message)
        // handleError()
        // navigate("/login")
        // responseMessage('Phone number already registered')
        // alert(<h4 className=" text-2xl text-red-500">this phone number Already registered!</h4>)
        // setPhoneError(true)
        // throw new Error(response.message);
      }
    } catch (error) {
      form.resetFields();
      setPhone('')
      toast.error(error.response.message)

      message.error(error.message);
      console.log(error.message, "error");
    }
  };

  const handlePhoneChange = (value) => {
    console.log(" Phone Change ", value);
    const phoneNumber = value.replace(/\D/g, ""); // Remove non-digit characters
    setPhone(phoneNumber);
  };

  const verifyEmail = async (e) => {
    e.preventDefault()

    try {
      const response = await axiosInstance.post("/api/auth/send-otp", { email: veerifyEmail })
      const data = await response.data
      setVerifyResponse(data)
      toast.success(data?.message)
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }

  const verifyOtp = async (e) => {
    e.preventDefault()

    try {
      const response = await axiosInstance.post("/api/auth/verifyotp", { email: veerifyEmail, otp: otp })
      const data = await response.data
      setOtpVerifyResponse(data)
      toast.success(data?.message)
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }


  useEffect(() => {
    fetchCategories()
  }, [])
  return (
    <div className="Register font-nunito md:pb-[50px] mt-[40px] max-lg:w-full max-lg:m-0 max-lg:p-0 max-sm:p-0 max-sm:w-full ">

      <div className="flex flex-row w-[50rem] md:ml-1 max-sm:w-full max-sm:m-0 max-sm:p-0  ml-[-205px] max-lg:w-full max-lg:p-0 max-lg:m-0">

        <div className="grid grid-cols-2 gap-5 md:ml-[-80px] md:mt-0 mt-20 w-[70rem]  max-sm:m-0 max-sm:p-0 max-sm:w-full
         max-lg:w-full max-lg:m-0 max-lg:p-0  shadow-lg  p-2">

          <div className="w-full h-full">
            <img
              className="object-cover hidden md:block h-full"
              alt=""
              src="/rectangle-10891@2x.png"
            />
          </div>


          <div className="w-full flex flex-col justify-center items-center ">
            <h2 className="md:ml-0 mb-3 max-sm:m-0 max-sm:w-full max-sm:my-5 max-lg:ml-28 max-lg:my-5 max-lg:w-full text-center w-full">Create Account</h2>
            <Form onFinish={onFinish} className="form  flex flex-row justify-center max-sm:justify-start pb-10 max-sm:p-0 max-sm:m-0 max-lg:p-0 max-lg:m-0 max-lg:w-full">
              <div className="w-full grid grid-cols-1">
                <div className="w-full flex justify-center items-center">
                  <img src="/LOGO_MAKE_MY_BUCKET.png" alt="logo" className="w-[195px] h-32" />
                </div>
                <div className="max-sm:m-0 max-sm:w-full ml-14 mt-5 ">
                  <Form.Item name="fullName" rules={[{ required: true, validator: validateInput },]} className="w-full">
                    <div className="w-full flex flex-col gap-2">
                      <label>Full Name: </label>
                      <Input type="text" required name="fullName" className=" w-[300px] " placeholder="Full Name" />
                    </div>
                  </Form.Item>
                  <Form.Item name="phone" className="w-full" rules={[{ required: true, validator: validatePhoneNumber }]}>
                    <div className="w-full">
                      <label>Mobile Number</label>
                      <PhoneInput
                        required
                        country={"in"}
                        value={phone}
                        onChange={handlePhoneChange}

                      // inputStyle={WidthFull}
                      />
                    </div>
                  </Form.Item>
                  <Form.Item name="email" rules={[{ required: true, validator: validateEmail }]}>

                    <div className=" flex flex-col gap-2">
                      <label>Email:</label>
                      <Input required type="email" name="Email" value={email} onChange={(e) => {
                        setEmail(e.target.value)
                        setVerifyEmail(e.target.value)
                      }} className=" w-[300px] " placeholder="johndoe@gmail.com" />

                      <div>
                        <button type="button" onClick={verifyEmail} className="bg-blue-600 rounded-lg py-2 w-24 text-white">Send OTP</button>
                      </div>


                    </div>

                  </Form.Item>
                  {
                    verifyResponse?.success && <div className="w-full flex gap-5 mb-2">
                      <input type="text" placeholder="type otp" onChange={(e) => setOtp(e.target.value)} />
                      <button type="button" onClick={verifyOtp} className="bg-blue-600 rounded-lg py-2 w-24 text-white">Verify Otp</button>
                    </div>
                  }
                  <Form.Item name="password" rules={[{ required: true, validator: validatePassword }]}>
                    <div className="flex flex-col gap-2">
                      <label>Password</label>
                      <Input type="password" required placeholder="Password" className=" w-[300px]" />
                    </div>
                  </Form.Item>
                </div>

              </div>

              <div className="w-full flex flex-col justify-center items-center p-2 gap-3 ml-14">
                <div className="w-full">
                  <p>Area's of Interest</p>
                </div>
                <div className="w-full grid grid-cols-2 max-sm:grid-cols-1 gap-3">
                  {
                    categoriesList?.category?.map((cat) => {
                      return (
                        <div className="w-full flex justify-start items-center" key={cat?._id}>
                          <input type="checkbox" checked={selectedInterest?.includes(cat?._id)} onChange={() => handleChangeCategoryId(cat?._id)} />
                          <label>{cat?.name}</label>
                        </div>
                      )
                    })
                  }
                </div>
                {/* <div className="w-full flex flex-col gap-2 justify-center items-center">
                <div className="w-full flex justify-start items-center">
                  <input type="checkbox" />
                  <label>Sky Diving</label>
                </div>
                <div className="w-full flex justify-start items-center">
                  <input type="checkbox" />
                  <label>Scuba Diving</label>
                </div>
                <div className="w-full flex justify-start items-center">

                  <input type="checkbox" />
                  <label> Checking</label>
                </div>

              </div> */}
              </div>
              <div className="w-[70%] flex flex-col gap-2 justify-center items-center mt-5 max-sm:m-0 max-sm:w-full ml-14">

                {/* <button className="submit-btn bg-darkslateblue-100 " type="submit">
                Submit
              </button> */}
                <button type="submit" className=" bg-darkslateblue-100 w-full rounded px-5">
                  Submit
                </button>
                <span className="float-right">
                  Already have an account?
                  <Link to='/login'>
                    <span className="hover:text-blue-500 cursor-pointer ml-1">
                      Login
                    </span>
                  </Link>
                </span>

              </div>
            </Form>
          </div>
          {/* <div id="googleSigninDiv" className="googleSigninDiv"></div> */}
          {/* <FacebookLoginComponent /> */}
          {/* <span></span> */}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}