import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export default function SearchItemCard({
  id,
  name,
  description,
  area,
  price,
  images,
  duration,
  durationType,
  location,
  options,
  totalCapacity,
  organizer,
  rating,
  createdAt,
  updatedAt,
  item,
}) {
  const navigate = useNavigate();
  console.log(images[0]);
  console.log(item);
  return (
    // .item - image{
    //     border - radius: 1rem;
    //     width: 300px;
    //     height: 250px;
    //   }
    <div className="flex gap-5 shadow-lg rounded flex-row p-2 max-sm:flex-col max-sm:w-full max-sm:text-sm">
      <div className="md:item-image md:w-[300px] max-lg:w-96 max-sm:w-full h-56">
        <img src={images[0]} alt="img" className="w-full h-full rounded-md max-sm:h-60 max-lg:w-96" />
      </div>
      <div className="item-detail w-full max-sm:m-0">
        <div className="flex w-full justify-between items-center">
          <h2 className="line-clamp-1 w-[80%] max-sm:w-full max-sm:line-clamp-2 max-sm:text-lg">{name}</h2>
          <p className="flex px-4 py-2 bg-orange-500 rounded-full text-white font-semibold max-sm:p-2">
            <p className="max-sm:w-full">{duration}</p>
            <p>{durationType} days</p>
          </p>
        </div>

        <span className="flex flex-row">
          {[0, 1, 2, 3, 4].map(() => (
            <img
              className="relative w-[15.55px] h-[15.64px]"
              alt=""
              src="/magicstar20.svg"
            />
          ))}
          {rating}
        </span>
        <div className="flex flex-row">
          <img
            className="relative w-5 h-5 overflow-hidden shrink-0"
            alt=""
            src="/location1.svg"
          />

          <span className="font-bold">{location}</span>
        </div>
        <span>* BY {organizer}</span>
        <div className="w-[80%]">
          <div>
            <span className="line-clamp-2">{description}</span>
          </div>
        </div>
        {/*  */}
        <div className="flex justify-between items-center w-full max-sm:w-full max-sm:flex-col max-sm:justify-start max-sm:items-start max-sm:gap-5">
          <div>
            <h2>₹{price}</h2>
          </div>
          <span
            onClick={() => {
              console.log(item, "book now error why");
              navigate("/select", {
                state: { item: { ...item } },
              });
            }}
            className="hover:cursor-pointer bg-blue-700 py-[12px] w-40 md:ml-64 rounded-md text-white text-center shadow-sm max-sm:w-full">
            BOOK NOW
          </span>
        </div>
      </div>
      <span className="item-duration">
        {duration} {durationType}
      </span>

    </div>
  );
}
