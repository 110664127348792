import React, { useEffect } from 'react'
import './selecteditem.css'
// import Navbar from '../Components/Navbar'
// import SelectedItemBody from '../Components/SelectionItemBody'
import SearchHeader from '../Components/SearchHeader'
// import Footer from '../Components/Footer'
import { useLocation } from 'react-router-dom'
import SelectedBody from '../Components/SelectionItemBody/SelectedBody'

export default function SelectedItem() {
  let locate = useLocation();
  console.log(locate.state.item, 'selected item check for search')
  useEffect(()=>{
    window.scrollTo(0,0)
   
  },[locate])

 
  return (
    <div className='SelectedItem overflow-x-hidden max-w-screen'>
      {/* <NavbarSearch/>
         */}
      {/* <Navbar/> */}
      <SearchHeader item={locate?.state?.item ?? locate?.state} />
      {/* <SelectedItemBody item={locate?.state?.item ?? locate?.state} /> */}
      <SelectedBody item={locate?.state?.item ?? locate?.state} />
      {/* <Footer/> */}
    </div>
  )
}
