import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import axiosInstance from '../apiCalls/axiosinstance'
import { useParams } from 'react-router-dom'
import DrawerAppBar from '../Components/Navbar/DrawerAppBar'
import Footer from '../Components/Footer'



const ResetPassword = () => {
  const [password, setPassword] = useState("")
  const [cpassword, setCPassword] = useState("")
  const { token } = useParams()

  const handleSubmitNewPassword = async (e) => {
    e.preventDefault()

    const passwordValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    if (!password) {
      toast.error("password is required")
      return
    }
    if (password?.length !== 8) {
      toast.error("password length should be 8")
      return
    }

    if (!passwordValidator?.test(password)) {
      toast.error("password must contain atleast 1 uppercase, 1 lowercase and 1 special characters")
      return
    }


    if (!password?.match(cpassword)) {
      toast.error("password and confirm password not matched matched")
      return
    }

    if (password?.match(cpassword)) {
      toast.success("password matched")
    }

    const fD = new FormData()
    fD.append("password", password)

    try {
      const response = await axiosInstance.post(`/api/auth/reset/${token}`, fD, { headers: { "Content-Type": "application/json" } })
      const data = await response?.data
      toast.success(data?.message)
      window.location.href = "/login"
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
  return (
    <div className='w-full flex flex-col justify-center items-center font-nunito'>
      <DrawerAppBar />
      <form className="w-[30%] flex flex-col justify-center items-center p-2" onSubmit={handleSubmitNewPassword}>


        {/* <div className="w-full flex flex-col justify-center items-start gap-3">
          <label>Link: </label>
          <input type="text" name="password" id="password" className="w-72" placeholder="Paste link from email" onChange={(e) => setLink(e.target.value)} />
        </div> */}
        <div className="w-full flex flex-col justify-center items-start gap-3">
          <label>Change Password:</label>
          <input type="password" name="password" id="password" className="w-72" placeholder="Type password" onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div className="w-full flex flex-col justify-center items-start gap-3">
          <label>Confirm Password:</label>
          <input type="password" name="cpassword" id="password" className="w-72" placeholder="Type Confirm password" onChange={(e) => setCPassword(e.target.value)} />
        </div>
        <p className="py-3 text-start"> ( password length should be 8 and must contain 1 uppercase, 1 lowercase and 1 special character )</p>
        <div className="w-full flex justify-start items-center">
          <button className="px-5 py-2 bg-blue-500 text-white rounded-lg mt-3">Submit</button>
        </div>
      </form>
      <div className='w-full absolute bottom-0'>
        <Footer />
      </div>
    </div>
  )
}

export default ResetPassword