import { Stack } from '@mui/material'
import { Pagination } from 'antd'
import React, { useState } from 'react'

const CompletedBooking = ({ historyBooking }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 6


    // pagination filter count data 

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = historyBooking.slice(indexOfFirstItem, indexOfLastItem)

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    return (
        <div>

            <div className="w-full flex flex-col justify-center items-center shadow-lg p-2 border-2 max-sm:w-full rounded max-sm:mt-2 max-sm:px-0">
                <div className="w-full">
                    <h1 className="px-6 py-5 max-sm:px-2 text-lg text-start w-full">Completed  Booking</h1>
                </div>
                <div className='w-full'>
                    {
                        !historyBooking?.length ? <div>No Completed Bookings Found</div> : <div>
                            <div className="flex justify-center items-center w-full">
                                <div className="w-full text-xs bg-[#F2F2F2] ">
                                    <div className="flex w-full py-2 px-5 rounded max-sm:px-1">
                                        <div className="w-full bg-white p-2 border-e-2 rounded-s-lg flex flex-col gap-2">
                                            <h1 className='text-lg'>Product</h1>
                                            <p className="w-full line-clamp-2">Aqua the Luxury Farmhouse Hotel, Goa..</p>
                                        </div>
                                        <div className="w-full bg-white p-2 border-e-2 flex flex-col gap-2">
                                            <div>
                                                <h1 className='text-lg'>Trip Details</h1>
                                            </div>
                                            <p>12:00 PM 23 May</p>
                                        </div>
                                        <div className="w-full bg-white p-2 border-e-2 flex flex-col gap-2">
                                            <div>
                                                <h1 className='text-lg'>Journey Date</h1>
                                            </div>
                                            <p>12:00 PM 24 May</p>
                                        </div>
                                        <div className="w-full bg-white p-2 border-e-2 flex flex-col gap-2">
                                            <div>
                                                <h1 className='text-lg'>Booking Date</h1>
                                            </div>
                                            <p>1 Room / 3 Guests</p>
                                        </div>
                                        <div className="w-full bg-white p-2 border-e-2 flex flex-col gap-2">
                                            <div>
                                                <h1 className='text-lg'>Booking Status</h1>
                                            </div>
                                            <p>1 Room / 3 Guests</p>
                                        </div>
                                        {/* <div className="w-full flex flex-col gap-2 bg-white p-2 rounded-e-lg">
                                <button type="button" className="bg-[#2152B1] text-white py-1 px-2 rounded" >View & Manage</button>
                                <button type="button" className="bg-[#FF3333] text-white py-1 px-2 rounded">Cancel Bookings</button>
                            </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full text-sm mt-10">
                                <table className="w-full text-center">
                                    <thead className="w-full">
                                        <tr className="">
                                            <th className="">Product</th>
                                            <th className="">Trip Details</th>
                                            <th className="">Journey Date</th>
                                            <th className="">Booking Date</th>
                                            <th className="">Booking Status</th>
                                            {/* <div className="text-sm font-medium">View and Manage</div> */}
                                        </tr>
                                    </thead>
                                    <tbody className="w-full">
                                        {currentItems.map((h) => {
                                            return (
                                                <tr className="">
                                                    <td className=" text-orange-800 font-bold">
                                                        {h.option.name}
                                                    </td>
                                                    <td className="flex justify-center items-center flex-wrap w-72 text-center">{h.option.description}</td>
                                                    <td className="">{h.date?.split("T")[0]}</td>
                                                    <td className=" ">{h.createdAt?.split("T")[0]}</td>
                                                    <td className="text-green-600">{h.status}</td>
                                                    {/* <div className=" rounded-md bg-darkslateblue-100 shadow-[0px_2px_6px_rgba(0,_0,_0,_0.14)] overflow-hidden flex flex-row py-[3px] px-3.5 items-center justify-center text-center text-sm text-white border-[1px] border-solid border-button-stroke">
                          View and Manage
                        </div> */}
                                                </tr>
                                            );
                                        })}
                                        {/* <tr className="text-center">
                                <td>GR12346655</td>
                                <td className="line-clamp-1">Aqua the Luxury Farmhouse Hotel, Goa..</td>
                                <td>12:00 PM 23 May</td>
                                <td>12:00 PM 24 May</td>
                                <td>Completed</td>
                                <td>
                                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded" > View & Manage</button>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>GR12346655</td>
                                <td className="line-clamp-1">Aqua the Luxury Farmhouse Hotel, Goa..</td>
                                <td>12:00 PM 23 May</td>
                                <td>12:00 PM 24 May</td>
                                <td>Completed</td>
                                <td>
                                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded" > View & Manage</button>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>GR12346655</td>
                                <td className="line-clamp-1">Aqua the Luxury Farmhouse Hotel, Goa..</td>
                                <td>12:00 PM 23 May</td>
                                <td>12:00 PM 24 May</td>
                                <td>Completed</td>
                                <td>
                                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded" > View & Manage</button>
                                </td>
                            </tr>
                            <tr className="text-center">
                                <td>GR12346655</td>
                                <td className="line-clamp-1">Aqua the Luxury Farmhouse Hotel, Goa..</td>
                                <td>12:00 PM 23 May</td>
                                <td>12:00 PM 24 May</td>
                                <td>Completed</td>
                                <td>
                                    <button className="bg-[#2152B1] text-white py-1 px-2 rounded" > View & Manage</button>
                                </td>
                            </tr> */}
                                    </tbody>
                                </table>
                            </div>
                            <div className='w-full justify-center items-center flex py-2'>
                                <Stack spacing={2}>

                                    <Pagination
                                        count={Math.ceil(historyBooking?.length / itemsPerPage)}
                                        variant="outlined"
                                        shape="rounded"
                                        page={currentPage}
                                        onChange={handlePageChange} />
                                </Stack>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CompletedBooking