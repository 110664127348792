// import React from 'react'

import { useState } from "react"
import DrawerAppBar from "../Components/Navbar/DrawerAppBar"
import { toast } from "react-toastify"
import axiosInstance from "../apiCalls/axiosinstance"
import axios from "axios"
import Footer from "../Components/Footer"

const ForgetPassword = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [cpassword, setCPassword] = useState("")
    const [link, setLink] = useState("")


    const handleVerifyEmail = async (e) => {
        e.preventDefault()

        const fD = new FormData()
        fD.append("email", email)

        try {
            const response = await axiosInstance.post("/api/auth/forgot", fD, { headers: { "Content-Type": "application/json" } })
            const data = await response?.data
            toast.success(data.message)
            
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }

    const handleSubmitNewPassword = async (e) => {
        e.preventDefault()

        const passwordValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

        if(!password){
            toast.error("password is required")
            return
        }
        if (password?.length !== 8) {
            toast.error("password length should be 8")
            return
        }

        if(!passwordValidator?.test(password)) {
            toast.error("password must contain atleast 1 uppercase, 1 lowercase and 1 special characters")
            return
        }


        if (!password?.match(cpassword)) {
            toast.error("password and confirm password not matched matched")
            return
        }

        // if(password?.match(cpassword)){
        //     toast.success("password matched")
        // }

        const fD = new FormData()
        fD.append("password", password)
        fD.append("email", email)
        fD.append("otp", link)

        try {
            const response = await axiosInstance.post("/api/auth/reset", fD, { headers: { "Content-Type": "application/json" } })
            const data = await response?.data
            toast.success(data?.message)
            window.location.href = "/login"
        } catch (error) {
            toast.error(error?.response?.data?.message)
        }
    }


    return (
        <div className="font-nunito w-full flex flex-col gap-3 justify-center items-center py-10">
            <DrawerAppBar />
            <div className="w-[40%] flex justify-center items-center flex-col px-28 py-4 max-sm:px-5 max-sm:w-full">
                <form className="w-full flex flex-col justify-center items-center p-2" onSubmit={handleVerifyEmail}>
                    <div className="w-full flex flex-col justify-center items-start gap-3">
                        <label>Verify Email:</label>
                        <input type="email" name="email" id="email" className="w-72" placeholder="Type email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="w-full flex justify-start items-center">
                        <button className="px-5 py-2 bg-blue-500 text-white rounded-lg mt-3">Verify</button>
                    </div>
                </form>

                <form className="w-full flex flex-col justify-center items-center p-2" onSubmit={handleSubmitNewPassword}>


                    <div className="w-full flex flex-col justify-center items-start gap-3">
                        <label>Otp: </label>
                        <input type="text" name="password" id="password" className="w-72" placeholder="Enter OTP" onChange={(e) => setLink(e.target.value)} />
                    </div>
                    <div className="w-full flex flex-col justify-center items-start gap-3">
                        <label>Change Password:</label>
                        <input type="password" name="password" id="password" className="w-72" placeholder="Type password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="w-full flex flex-col justify-center items-start gap-3">
                        <label>Confirm Password:</label>
                        <input type="password" name="cpassword" id="password" className="w-72" placeholder="Type Confirm password" onChange={(e) => setCPassword(e.target.value)} />
                    </div>
                   <p className="py-3 text-start"> ( password length should be 8 and must contain 1 uppercase, 1 lowercase and 1 special character )</p>
                    <div className="w-full flex justify-start items-center">
                        <button className="px-5 py-2 bg-blue-500 text-white rounded-lg mt-3">Submit</button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>

    )
}

export default ForgetPassword