import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { cancelTheBooking, getMyBookings } from '../apiCalls/booking';
import { message } from 'antd';
import DrawerAppBar from '../Components/Navbar/DrawerAppBar';
import Footer from '../Components/Footer';
import { toast } from 'react-toastify';

const DetailBookingStatus = () => {
    const { _id } = useParams()
    const [upcomingBooking, setUpcomingBooking] = useState([]);
    const [historyBooking, setHistoryBooking] = useState([]);
    const [cancellationMoney, setCancellationMoney] = useState()
    const [refundAmount, setRefundAmount] = useState()
    const [cancelF, setCancelF] = useState(false)
    const [filterData, setFilterData] = useState([])

    const navigate = useNavigate()
    console.log(_id);
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (location?.state !== null) {
            const { cancellationMoneys, refundAmounts } = location?.state
            setCancellationMoney(cancellationMoneys)
            setRefundAmount(refundAmounts)
        }
    }, [])



    const getMyBooking = async () => {
        const token = JSON.parse(localStorage.getItem("token")) // Replace with how you store the authentication token

        try {
            const response = await getMyBookings(token);
            console.log(response, "myresponse");
            if (response.success) {
                setHistoryBooking(response["history"]);
                setUpcomingBooking(response["upcoming"]);
                // console.log("my booking history", historyBooking);
                // console.log("my booking upcoming", upcomingBooking);
            } else {
                throw new Error(response.error);
            }
        } catch (error) {
            message.error(error.message);
        }
    };

    const handleCancelSuccess = () => {
        toast.success('successfuly Canceled!')
    }

    const cancelBooking = async (e) => {
        e.preventDefault()
        try {
            if (window.confirm("Are you sure want to cancel booking?")) {
                const response = await cancelTheBooking(_id);
                console.log(response);

                if (response.success) {
                    handleCancelSuccess()
                    console.log('success');
                    // navigate(`/`)
                    window.location.reload()
                    // window.location.p
                } else {

                    throw new Error(response.message);
                }
            }
        } catch (error) {

            console.log(error.message, "error");
        }
    }

    useEffect(() => {
        const filterData = upcomingBooking?.filter((item) => item?._id === _id)
        console.log(filterData, "-----filtered data");
        setFilterData(filterData)
    }, [upcomingBooking, _id])

    console.log(upcomingBooking);

    useEffect(() => {
        getMyBooking()
    }, [_id])

    function formatDate(inputDate) {
        const date = new Date(inputDate);

        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = [
            "January", "February", "March", "April", "May", "June", "July", "August",
            "September", "October", "November", "December"
        ];

        const day = days[date.getUTCDay()];
        const dayOfMonth = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();

        return `${day}, ${dayOfMonth} - ${month} - ${year}`;
    }

    // const formattedDate = formatDate("2024-06-29");
    // console.log(formattedDate); // Outputs: Saturday, 29 - June - 2024



    return (
        <div className='w-full'>
            <DrawerAppBar />
            <div className='font-nunito w-full px-20 py-2 max-sm:px-5'>

                <div className='w-full flex justify-start items-center p-2 gap-2'>
                    <p className='font-bold'>{filterData[0]?.status?.toLowerCase() === "cancelled" ? "Cancellation" : "Ordered"} Date:</p>
                    <p>{filterData[0]?.createdAt?.split("T")[0]}</p>
                </div>
                <div className='w-full justify-center items-start flex flex-col gap-5 p-2 max-sm:text-start'>
                    <h1 className={`text-2xl max-sm:text-xl ${filterData[0]?.status === "Cancelled" ? "text-red-700" : filterData[0]?.status === "Paid" ? "text-green-500" : filterData[0]?.status === "Pending" && "text-yellow-500"}`}>Your Booking Was {filterData[0]?.status === "Paid" ? "Confirmed" : filterData[0]?.status === "Cancelled" ? "Cancelled" : "Pending"}</h1>
                    <p>{filterData[0]?.status === "Cancelled" ? "We have sent to confirmation email to" : filterData[0]?.status === "Pending" ? "We will send confirmation email once booking is confirmed" : "We have sent confirmation mail to"} <b className='text-lg'>{filterData[0]?.contactDetails?.email}</b></p>

                    <h1 className='capitalize font-semibold' style={{ fontSize: "20px" }}>{formatDate(filterData[0]?.createdAt?.split("T")[0])}</h1>
                </div>
                <hr />
                <div className='w-full grid grid-cols-2 p-2 max-sm:grid-cols-1'>
                    <div className='flex flex-col w-full justify-center items-start gap-3'>
                        <div className='w-full text-start'>
                            <h1 className='' style={{ fontSize: "20px" }}>Cancellation Summary</h1>
                        </div>
                        <div className='w-full grid grid-cols-2 justify-center items-start'>

                            <div className='w-full flex flex-col justify-start items-start'>
                                <p>Product Name</p>
                                <p>Category</p>
                                <p>Travel Date</p>
                                <p>Booking Id</p>

                            </div>
                            <div className='w-full flex flex-col justify-start items-start'>
                                {filterData[0]?.status?.toLowerCase() === "cancelled" ? <del className="text-green-600 text-start line-clamp-1">{filterData[0]?.activity?.name}</del> : <p className="text-green-600 text-start line-clamp-1">{filterData[0]?.activity?.name}</p>}
                                {filterData[0]?.status?.toLowerCase() === "cancelled" ? <del className="text-green-600">{filterData[0]?.activity?.category?.name?.toString()}</del> : <p className="text-green-600">{filterData[0]?.activity?.category?.name?.toString()}</p>}
                                {filterData[0]?.status?.toLowerCase() === "cancelled" ? <del className="text-green-600">{filterData[0]?.date?.split("T")[0]}</del> : <p className="text-green-600">{filterData[0]?.date?.split("T")[0]}</p>}
                                {filterData[0]?.status?.toLowerCase() === "cancelled" ? <del className="text-green-600">{filterData[0]?.razorpay_order_id}</del> : <p className="text-green-600">{filterData[0]?._id}</p>}
                                {/* <del className="text-green-600">{filterData[0]?.activity?.name}</del> */}
                                {/* <del className="text-green-600">{filterData[0]?.activity?.category?.toString()}</del> */}
                                {/* <del className="text-green-600">{filterData[0]?.date?.split("T")[0]}</del> */}
                                {/* <del className="text-green-600">{filterData[0]?.razorpay_order_id}</del> */}

                            </div>
                        </div>
                        <div className='w-full text-start'>
                            <h1 style={{ fontSize: "20px" }}>Price Breakdown</h1>
                        </div>
                        <div className='w-full grid grid-cols-2 justify-center items-start'>

                            <div className='w-full flex flex-col justify-start items-start'>
                                <p>Total Price</p>
                                <p>Cancellation Fee</p>
                                <p>Refundable Amount</p>
                                {/* <p>Booking Id</p> */}

                            </div>
                            <div className='w-full flex flex-col justify-start items-start'>
                                <p>₹{(filterData[0]?.totalPrice)?.toFixed(2)}</p>
                                <p>₹{(filterData[0]?.totalPrice * 0.3)?.toFixed(2)}</p>
                                <p>₹{((filterData[0]?.totalPrice) - (filterData[0]?.totalPrice * 0.3))?.toFixed(2)}</p>
                            </div>

                        </div>
                        {
                            filterData[0]?.status == "Cancelled" && <div className='w-full justify-start items-center flex gap-5 my-5 font-semibold'>
                                <button className='bg-[#2152B1] px-5 py-2 rounded text-whitesmoke'
                                    onClick={() => {
                                        navigate("/select", { state: { item: filterData[0]?.activity } })
                                    }}
                                >Book again</button>
                                <p className='text-[#2152B1] cursor-pointer' onClick={() => navigate("/search?category=&location=&activity=")}>Explore Other Experience</p>
                            </div>
                        }
                        {
                            filterData[0]?.status !== "Cancelled" && <div>
                                <button className={`cancel-booking ${cancelF && "hidden"}`} onClick={() => setCancelF(true)}>Cancel Booking</button>
                            </div>
                        }
                        {
                            cancelF && filterData[0]?.status !== "Cancelled" && <div className="w-full flex justify-start items-start flex-col">
                                <h1 className=" text-[25px]">Reason For Cancelling</h1>

                                <label for="dog-names">Select Reason</label>
                                <div className=" flex justify-center mt-2">
                                    <select
                                        style={{
                                            width: "20rem",
                                            // padding: "10px 40px",
                                            height: "40px",
                                            display: "flex",
                                            justifyContent: "center",
                                            backgroundColor: "white",
                                            border: "1px solid black",
                                            borderRadius: "5px",
                                        }}
                                        name="reason"
                                        id="dog-names">
                                        <option value="rigatoni">Changed of Plans</option>
                                        <option value="dave">Personal Reason</option>
                                        <option value="dave">Health issue</option>
                                        <option value="dave">Weather concerns</option>
                                        <option value="dave">Accommodation issues</option>
                                        <option value="dave">Financial Issue</option>
                                        {/* <option value="dave">Health issue</option>
                                        <option value="dave">Health issue</option> */}
                                        <option value="dave">Others</option>
                                        {/* <option value="pumpernickel">Pumpernickel</option>
                  <option value="reeses">Reeses</option> */}
                                    </select>
                                </div>
                                <br />
                                <div className="w-full flex justify-start items-center gap-4 font-semibold">
                                    <button className="cancel-booking " onClick={cancelBooking}>Cancel Booking</button>
                                    <p className="text-blue-500 cursor-pointer hover:underline" onClick={() => setCancelF(false)}>Don’t Cancel this booking</p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='w-full justify-center items-center flex'>
                        <img src={filterData[0]?.activity?.images[0]} alt='logo' className='w-96 h-48 rounded object-center object-cover' />
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default DetailBookingStatus