import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./promotion.css";
import { toast } from "react-toastify";
import { getPromotions } from "../../apiCalls/Promotion";

export default function Promotion() {
  const history = useNavigate();
  const [promotionList, setPromotionList] = useState([])

  const handleClick = (_id) => {
    history(`/promotionListing/${_id}`);
  };

  const promotionsList = async () => {
    try {

      const response = await getPromotions()
      if (response) {
        setPromotionList(response?.promotion)
      }
    } catch (error) {
      toast.error("Error from Promotions")
    }
  }

  useEffect(() => {
    promotionsList()
  }, [])

  return (
    <div className="Promotion max-w-screen hidden font-nunito">
      <h1 className="max-sm:text-xl max-sm:w-full">Promotion & Exclusive Offers</h1>
      <hr className=' bg-orange-600 p-[2px] w-80  mb-10 max-sm:hidden' />

      <div className="promotion-cards max-sm:px-5 max-lg:w-full">

        {
          promotionList?.map((promotion, i) => {
            return (
              <div className="promotion-card shadow-xl rounded-lg max-sm:flex-col max-sm:w-full max-sm:p-2 max-sm:m-0 max-lg:w-full" key={promotion?._id}>
                <div className="promotion-card-image max-sm:w-full max-lg:w-full">
                  <img
                    className="rounded-md w-[134px] h-[134px] object-cover max-sm:w-full max-sm:rounded-b-none max-sm:rounded-s-none max-lg:w-52 max-lg:h-52"
                    alt=""
                    src={promotion?.image}
                  />
                </div>
                <div className="promotion-card-detail text-start mt-6 max-sm:mt-0 max-sm:py-5 max-sm:ml-6 max-sm:m-0 max-sm:p-0 max-sm:w-full max-lg:m-0 max-lg:p-0 max-lg:w-full">
                  <div className="inline-block w-[249px] max-lg:w-full">{promotion?.title}</div>
                  <div className="text-smi leading-[16px] text-darkslategray-100 inline-block w-[244px]">{promotion?.subtitle}</div>
                  <div className=" w-24
                 rounded-md bg-sandybrown-100 shadow-[0px_2px_6px_rgba(0,_0,_0,_0.14)] flex flex-row py-1.5 items-start justify-start  text-sm border-[1px] border-solid border-button-stroke ">
                    {/* <div className="relative leading-[24px]">Book Now</div> */}
                    <button className=" w-16" variant="contained" onClick={() => handleClick(promotion?._id)}>Book Now</button>
                  </div>
                </div>
              </div>
            )
          })
        }
        {/* <div className="promotion-card shadow-xl rounded-lg max-sm:flex-col max-sm:w-full max-sm:p-2 max-sm:m-0 max-lg:w-full">
          <div className="promotion-card-image max-sm:w-full max-lg:w-full">
            <img
              className="rounded-md w-[134px] h-[134px] object-cover max-sm:w-full max-sm:rounded-b-none max-sm:rounded-s-none max-lg:w-52 max-lg:h-52"
              alt=""
              src="/image1@2x.png"
            />
          </div>
          <div className="promotion-card-detail text-start mt-6 max-sm:mt-0 max-sm:py-5 max-sm:ml-6 max-sm:m-0 max-sm:p-0 max-sm:w-full max-lg:m-0 max-lg:p-0 max-lg:w-full">
            <div className="inline-block w-[249px] max-lg:w-full">{`Get Up to 25% OFF* on Flights, Hotels & Holidays`}</div>
            <div className="text-smi leading-[16px] text-darkslategray-100 inline-block w-[244px]">{`& get your next refreshing break sorted!`}</div>
            <div className=" w-24
             rounded-md bg-sandybrown-100 shadow-[0px_2px_6px_rgba(0,_0,_0,_0.14)] flex flex-row py-1.5 items-start justify-start  text-sm border-[1px] border-solid border-button-stroke ">
 
              <button className=" w-16" variant="contained" onClick={handleClick}>Book Now</button>
            </div>
          </div>
        </div>


        <div className="promotion-card shadow-xl rounded-lg max-sm:flex-col max-sm:w-full max-lg:w-full">
          <div className="promotion-card-image max-sm:w-full max-lg:w-full">
            <img
              className="rounded-md w-[134px] h-[134px] object-cover max-sm:w-full max-sm:rounded-b-none max-sm:rounded-s-none max-lg:w-52 max-lg:h-52"
              alt=""
              src="/image2@2x.png"
            />
          </div>
          <div className="promotion-card-detail text-start mt-6 max-sm:mt-0 max-sm:py-5 max-sm:ml-6 max-sm:m-0 max-sm:p-0 max-sm:w-full max-lg:w-full">
            <div className="inline-block w-[249px] max-lg:w-full">{`Get Up to 25% OFF* on Flights, Hotels & Holidays`}</div>
            <div className="text-smi leading-[16px] text-darkslategray-100 inline-block w-[244px]">{`& get your next refreshing break sorted!`}</div>
            <div className=" w-24
             rounded-md bg-sandybrown-100 shadow-[0px_2px_6px_rgba(0,_0,_0,_0.14)] flex flex-row py-1.5 items-center justify-center text-center text-sm border-[1px] border-solid border-button-stroke">
  
              <button className=" w-16" variant="contained" onClick={handleClick}>Book Now</button>
            </div>
          </div>
        </div>
        <div className="promotion-card shadow-xl rounded-lg max-sm:flex-col max-sm:w-full max-lg:w-full">
          <div className="promotion-card-image max-sm:w-full max-lg:w-full">
            <img
              className="rounded-md w-[134px] h-[134px] object-cover max-sm:w-full max-sm:rounded-b-none max-sm:rounded-s-none max-lg:w-52 max-lg:h-52"
              alt=""
              src="/image1@2x.png"
            />
          </div>
          <div className="promotion-card-detail text-start mt-6 max-sm:mt-0 max-sm:py-5 max-sm:ml-6 max-sm:m-0 max-sm:p-0 max-sm:w-full max-lg:w-full">
            <div className="leading-[22px] inline-block w-[249px] max-lg:w-full">{`Get Up to 25% OFF* on Flights, Hotels & Holidays`}</div>
            <div className="text-smi leading-[16px] text-darkslategray-100 inline-block w-[244px]">{`& get your next refreshing break sorted!`}</div>
            <div className=" w-24
             rounded-md bg-sandybrown-100 shadow-[0px_2px_6px_rgba(0,_0,_0,_0.14)] flex flex-row py-1.5 items-center justify-center text-center text-sm border-[1px] border-solid border-button-stroke">
        
              <button className=" w-16" variant="contained" onClick={handleClick}>Book Now</button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
