import axios from 'axios';

// const axiosInstance = axios.create({
//   // baseURL: "http://makemybucketlist.myappsdevelopment.co.in/",
//   baseURL: "https://makemybucketlist.myappsdevelopment.co.in/",
//   // baseURL: "http://localhost:4000/",
//   // baseURL: "https://ghumore-travel.onrender.com/",
//   // baseURL : "http://api.makemybucketlist.in/",
//   // baseURL : "https//backend.api.makemybucketlist.in/",
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   },
// });

// export default axiosInstance;


// import { useNavigate } from "react-router-dom";

export const BASE_URL = "https://makemybucketlist.myappsdevelopment.co.in/";

let userToken;
try {
  userToken = JSON.parse(localStorage.getItem("token"));
} catch (error) {
  console.error("Invalid token in localStorage", error);
  userToken = null;
  localStorage.removeItem("token")
}

const userRole = localStorage.getItem("role");
console.log('AXIOS TOKEN', userToken);
console.log('ROLE', userRole); // Assuming role is a simple string, no need to parse

 const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: userToken ? { Authorization: `Bearer ${userToken}` } : {},
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized error
      localStorage.removeItem("token");
      // Redirect to login page or any other desired location
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance

