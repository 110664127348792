import React, { useEffect, useState } from 'react'
import './experience.css'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchAllCategories } from '../../apiCalls/categories'

export default function Experience() {
  const navigate = useNavigate()
  const [categoryList, setCategoryList] = useState([])

  const getCatgoryList = async () => {
    try {
      const response = await fetchAllCategories();
      console.log(response);
      if (response?.success) {
        setCategoryList(response?.category)
      } else {
        throw response
      }

    } catch (error) {
      toast.error(error?.response?.message || "Error from Backend")
    }
  }
  console.log(categoryList, "categoryList");

  useEffect(() => {
    getCatgoryList()
  }, [])
  return (
    <div className='Experience font-nunito max-lg:mt-5'>
      <h2 className=' text-[30px] lg:mt-20 md:text-[35px] max-sm:w-full max-sm:text-lg max-sm:mt-5 max-sm:px-10 max-lg:w-[80%]'> We Offer a wide range of experience across the world</h2>
      {/* <hr className=' bg-orange-600 p-[2px] w-80 md:ml-[670px] ' /> */}

      {
        !categoryList.length && <div className='w-full'>No Category Found</div>
      }
      <div className='experience-grid'>
        {
          categoryList?.slice(0, 6)?.map((category) => {
            return (
              <div key={category?._id} className='experience-card  cursor-pointer' onClick={() => navigate(`/search?category=${category?.name}&location=&activity=`)}>
                <img src={category?.image} alt={category?.name} className='h-full object-cover' />
                <p className='absolute capitalize'>{category?.name}</p>
              </div>
            )
          })
        }
      </div>
      {/* <div className='experience-grid max-lg:gap-5'>
        <div className='experience-card bg-[url(/public/frame-17@3x.png)] bg-cover bg-no-repeat bg-[top] h-96 max-sm:h-52 max-sm:ml-10 max-lg:ml-5 cursor-pointer' onClick={() => navigate("/search?category=trekking&location=&activity=")}><span className='text-end'>Trekking</span></div>
        <div className='experience-card bg-[url(/public/frame-16@3x.png)] bg-cover bg-no-repeat bg-[top] h-96 max-sm:h-52 max-sm:ml-10 max-lg:ml-5 cursor-pointer' onClick={() => navigate("/search?category=skydiving&location=&activity=")}><span>SkyDiving</span></div>
        <div className='experience-card bg-[url(/public/frame-18@3x.png)] bg-cover bg-no-repeat bg-[top] h-96 max-sm:h-52 max-sm:ml-10 max-lg:ml-5 cursor-pointer' onClick={() => navigate("/search?category=paragliding&location=&activity=")}><span>Paragliding</span></div>
        <div className='experience-card bg-[url(/public/frame-20@3x.png)] bg-cover bg-no-repeat bg-[top] h-96 max-sm:h-52 max-sm:ml-10 max-lg:ml-5 cursor-pointer' onClick={() => navigate("/search?category=Rainforest Tours&location=&activity=")}><span>Rainforest Tours</span></div>
        <div className='experience-card bg-[url(/public/frame-19@3x.png)] bg-cover bg-no-repeat bg-[top] h-96 max-sm:h-52 max-sm:ml-10 max-lg:ml-5 cursor-pointer' onClick={() => navigate("/search?category=Tree Ziplining&location=&activity=")}><span>Tree Ziplining</span></div>
        <div className='experience-card bg-[url(/public/frame-21@3x.png)] bg-cover bg-no-repeat bg-[top] h-96 max-sm:h-52 max-sm:ml-10 max-lg:ml-5 cursor-pointer' onClick={() => navigate("/search?category=skydiving&location=&activity=")}><span>SkyDiving</span></div>
      </div> */}
    </div>
  )
}
