import React from 'react';

const ActivityDetail = ({ response }) => {
    const divStyle = {
        padding: '1px',
        marginTop: '35px',
        textAlign: 'start'
    };

    const headingStyle = {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '25px',
        textAlign: 'start'
    };

    const filterAndStyleH4 = (htmlContent) => {
        // Define a regex to match <h4> and <li> tags
        const h4Regex = /<h4\b[^>]*>([\s\S]*?)<\/h4>/g;
        const liRegex = /<li\b[^>]*>([\s\S]*?)<\/li>/g;

        // Filter and style <h4> tags
        const filteredContent = htmlContent.replace(h4Regex, (match, content) => {
            const styledContent = `<h4 style="font-size:${headingStyle.fontSize};font-weight: ${headingStyle.fontWeight};">${content}</h4>`;
            return styledContent;
        });

        // Filter and style <li> tags
        const styledContentWithLi = filteredContent.replace(liRegex, (match, content) => {
            // Check if the <li> contains a <ul> or <ol> to decide bullet style
            const isNestedList = content.includes('<ul>') || content.includes('<ol>');
            const fontWeight = isNestedList ? 'normal' : 'normal'; // semi-bold for nested lists, bold for top-level lists
            const margin = isNestedList ? "" : "10px";
            const styledLi = `<li style="font-weight: ${fontWeight}; list-style-type: disc;">${content}</li>`;
            return styledLi;
        });

        return styledContentWithLi;
    };

    const filteredOverview = filterAndStyleH4(response.overview);

    return (
        <div style={divStyle}
            dangerouslySetInnerHTML={{ __html: filteredOverview }}
        />
    );
};

export default ActivityDetail;
