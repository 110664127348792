import React, { useEffect, useState } from "react";
import { Form, message, Input, Button } from "antd";
import PhoneInput from "react-phone-input-2";
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateUserProfile } from "../../../apiCalls/users";



const Profile = ({setUserName}) => {

    const [data, setData] = React.useState({
        name: "",
        email: "",
        address: "",
        city: "",
    });
    const { user } = useSelector((state) => state.users);
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const [phone, setPhone] = useState("");
    const [loader, setLoader] = useState(false)

    console.log(user, "----user");


    const rules = [{}];

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(" Name ", name, value, e.target)
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        console.log(" Data ", data)
    };

    const handleUpdateSuccess = () => {
        toast.success('Profile successfully updated');
    }

    const handlePhoneChange = (value) => {
        setPhone(value);
    };

    const onFinish = async (e) => {
        e.preventDefault()
        // e.preventdefault()
        console.log("On Finish Called")
        data.phoneNumber = phone;
        console.log(data, "finish");
        try {
            // dispatch(setLoader(true));
            

            const response = await UpdateUserProfile(user._id, data);
            console.log(response, data, " Update Response");
            // dispatch(setLoader(false));
            if (response.success) {
                handleUpdateSuccess()
                toast.success(response?.data?.user?.message)
                // message.success(response.message);
                // navigate("/");
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            message.error(error.message);
            console.log(error.message, "error");
            toast.error(error?.response?.data?.message)
        }
    };

    //   const handlePasswordChange = (e) => {
    //     const { name, value } = e.target;
    //     setDataPassword((prevData) => ({
    //       ...prevData,
    //       [name]: value,
    //     }));
    //   };

    useEffect(() => {
        if (user) {
            setData({
                fullName: user.fullName || "",
                phone: user.phone || "",
                email: user.email || "",
                address: user.address || "",
                city: user.city || "",
            });
            setPhone(user.phoneNumber || "");
        }
        // getMyBooking();
        setUserName(user?.fullName)
    }, [user]);
    return (
        <div className="w-full">
            <div>
                <h1>Profile Details</h1>
            </div>
            <form onSubmit={onFinish} className="w-full grid grid-cols-2 ">
                {/* <Form.Item noStyle name="fullName" rules={rules}> */}
                <div className="form-item">
                    <label>Full Name</label>
                    <Input
                        value={data.fullName}
                        onChange={handleChange}
                        type="text"
                        required
                        name="fullName"
                        placeholder="Full Name"
                    />
                </div>
                {/* </Form.Item> */}
                {/* <Form.Item noStyle rules={rules}> */}
                <div className="form-item">
                    <label>Mobile Number</label>
                    <PhoneInput
                        country={"in"}
                        value={phone}
                        onChange={handlePhoneChange}
                        name="phone"
                        required
                    />
                </div>
                {/* </Form.Item> */}
                {/* <Form.Item noStyle name="email" rules={rules}> */}
                <div className="form-item">
                    <label>Email</label>
                    <Input
                        value={data.email}
                        type="email"
                        required
                        onChange={handleChange}
                        placeholder="Email"
                        name="email"
                    />
                </div>
                {/* </Form.Item> */}
                {/* <Form.Item noStyle name="address" rules={rules}> */}
                <div className="form-item">
                    <label>Address</label>
                    <Input
                        name="address"
                        type="text"
                        required
                        value={data.address}
                        onChange={handleChange}
                        placeholder="Address"
                    />
                </div>
                {/* </Form.Item> */}
                {/* <Form.Item noStyle name="city" rules={rules}> */}
                <div className="form-item">
                    <label>City</label>
                    <Input
                        onChange={handleChange}
                        type="text"
                        placeholder="City"
                        required
                        value={data.city}
                        name="city"
                    />
                </div>
                <button className="w-80 h-10 mt-6 text-white rounded-lg col-span-2 bg-darkslateblue-100" type="submit" >
                    Save Changes
                </button>
                {/* </Form.Item> */}


            </form>

        </div>
    )
}

export default Profile