import { useNavigate, useParams } from "react-router-dom";
import Footer from "../Footer"
import DrawerAppBar from "../Navbar/DrawerAppBar"
import BlurImageLoader from 'react-blur-image-loader';
import { useEffect, useState } from "react";
import { getPromotions } from "../../apiCalls/Promotion";
import { toast } from "react-toastify";
import "./PromotionCard.css"


const PromotionListingPage = () => {
    const navigate = useNavigate()
    const [promotionList, setPromotionList] = useState([])
    const { _id } = useParams()




    const promotionsList = async () => {
        try {

            const response = await getPromotions()
            if (response) {
                setPromotionList(response?.promotion)
            }
        } catch (error) {
            toast.error("Error from Promotions")
        }
    }

    const result = promotionList?.filter(promoId => promoId?._id === _id)

    useEffect(() => {
        promotionsList()
    }, [])


    console.log(result);

    return (
        <div className="w-full font-nunito">
            <DrawerAppBar />
            <div>
                <div>
                    <h1>Promotion Activities List</h1>
                </div>
                {
                    !result[0]?.activities?.length && <div className="w-full h-96 flex justify-center items-center">No Activities Found</div>
                }
                <div className="max-lg:w-full max-lg:ml-10 w-full grid grid-cols-4 px-32 gap-10">

                    {result[0]?.activities?.map((i) => {
                        return (
                            <div className="activity-card">
                                <div className="activity-image">

                                    <BlurImageLoader
                                        src={i?.images[0]}
                                        // style={{borderRadius:"10px"}}
                                        fullCover={true}
                                        maxBlurLevel={0}
                                        transitionTime={0}
                                        className="rounded-lg"
                                    />

                                </div>
                                <h2 className="activity-title">{i?.name}</h2>
                                <span className="font-bold text-4xl">{i?.area}</span>

                                <span className="font-thin">{i?.location?.name} </span>
                                <span className="font-thin">{i?.category?.name}</span>

                                <p className="font-thin">
                                    from <span className="font-bold text-6xl">₹{i.price}</span> per person
                                </p>
                                <div className=" text-sm font-semibold flex font-barlow text-secondary text-left">

                                    <img
                                        className=" w-[15.55px] h-[15.64px]"
                                        alt=""
                                        src="/magicstar20.svg"
                                    />
                                    {i?.averageRating?.toFixed(1) || 0}
                                </div>

                                <div className="card-footer gap-5">
                                    {console.log('item to add', i)}
                                    <div
                                        onClick={() => {
                                            console.log(i, "book");
                                            navigate("/select", {
                                                state: { item: { ...i } },

                                            });
                                        }}

                                        className="btn-book rounded-lg bg-darkslateblue-100 shadow-[0px_2px_6px_rgba(0,_0,_0,_0.14)] overflow-hidden flex flex-row py-1.5 px-6 items-center justify-center border-[1px] border-solid border-button-stroke w-48">
                                        <div className=" text-white leading-[24px] text-center">
                                            Book Now
                                        </div>
                                    </div>
                                    <div className="btn-book rounded-lg bg-ghumore-orange  flex flex-row py-1.5 px-6 items-center justify-center text-center text-xs w-48">
                                        <div className="  text-white leading-[132%] font-medium  text-lg text-center">
                                            {i.duration} {i.durationType ?? "days"}  tour
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PromotionListingPage