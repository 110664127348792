import React, { useState, useRef, useEffect } from "react";
import { message } from "antd";
import DatePicker from "react-datepicker";
// import { DatePickerProps } from 'antd';
// import { DatePicker, Space } from 'antd';
import "./selectionitembody.css";
import "react-datepicker/dist/react-datepicker.css";
// import GuestPickerModal from "../../Pages/GuestPicker";
import { InputNumber, Button } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { addDays } from "date-fns";
import { checkActivityAvailability } from "../../apiCalls/activities";
import { useDispatch, useSelector } from "react-redux";
// import { setLoader } from "../../redux/loaderSlice";
import ActivityDetail from "../ActivityDetail";
import SimilarActivity from "../SimilarActivity";
import LocationMap from "../LocationMap";
// import { createCart } from "../../apiCalls/booking";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReviewComponent from "../ReviewComponent";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const SelectedBody = ({ item }) => {
    const [selectedRoomPackage, setSelectedRoomPackage] = useState(null)
    const [selectedOption, setSelectedOption] = useState("");

    const [adultselectionOpen, setAdultSelectionOpen] = useState(false)
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [selectedTime, setSelectedTime] = useState("");
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const [date, setDate] = useState(new Date());
    const [adults, setAdults] = useState(1);
    const [infants, setInfant] = useState(0);

    const { user } = useSelector((state) => state.users);
    const user_id = JSON.parse(localStorage.getItem("user"))
    var token = JSON.parse(localStorage.getItem("token"));


    const handleError = () => {
        toast.error("not added to cart please try again");
    };
    const handleSuccess = () => {
        toast.success("Activity has been successfully added to cart");
    };

    const handleAdultIncrease = () => {
        setAdults(adults + 1);
    };

    const handleAdultDecrease = () => {
        setAdults(adults - 1);
    };
    const handleInfantIncrease = () => {
        setInfant(infants + 1);
    };

    const handleInfantDecrease = () => {
        setInfant(infants - 1);
    };

    const handleCheckboxChange = (event) => {
        setSelectedOption(event.target.value);
    };

    // const handleBookingSubmit = async (e) => {
    //     e.preventDefault();

    //     console.log("clicked");

    //     console.log(selectedOption, selectedTime, 111);

    //     console.log(item._id, infants + adults, date, 222);

    //     if (!selectedOption || !selectedTime) {
    //         return message.error("Fill all the inputs");
    //     }

    //     if (!selectedOption) {
    //         toast.error("Package Selection is Required")
    //         return
    //     }

    //     if (!selectedTime) {
    //         toast.error("Time Selection is Required")
    //         return
    //     }

    //     if (!user) {
    //         navigate("/login")
    //         return
    //     }
    //     try {
    //         // dispatch(setLoader(true));
    //         // console.log('its not working')
    //         const response = await checkActivityAvailability(
    //             item._id,
    //             infants + adults,
    //             date
    //         );
    //         if (response) {
    //             // dispatch(setLoader(false));
    //             console.log('send gift')

    //             navigate("/securecheckout", {
    //                 state: {
    //                     selectedActivity: item,
    //                     selectedOption: item.options[selectedOption],
    //                     adults: adults,
    //                     infants: infants,
    //                     date: date?.toDateString(),
    //                     selectedTime: selectedTime,
    //                     activityId: item._id,
    //                 },
    //             });
    //         } else {
    //             throw new Error(response.message);
    //         }
    //     } catch (error) {
    //         // dispatch(setLoader(false));
    //         message.error(error.message);
    //     }
    // };



    console.log(item);

    const handleBookingSubmit = async (e) => {
        e.preventDefault();

        // console.log("clicked");

        // console.log(selectedOption, selectedTime, 111);
        // console.log(item._id, infants + adults, date, 222);

        // if (!selectedOption || !selectedTime) {
        //     return message.error("Fill all the inputs");
        // }

        if (!selectedOption) {
            toast.error("Package Selection is Required")
            return
        }

        // if(!date){
        //     toast.error("Date is Required")
        // }

        if (!selectedTime) {
            toast.error("Time Selection is Required")
            return
        }

        const payload = {
            activityId: item?._id,
            quantity: infants + adults,
            date: date,
            selectedActivity: item,
            selectedOption: item.options[selectedOption],
            adults: adults,
            infants: infants,
            selectedTime: selectedTime, 
        }

        if (!user_id && !token) {
            navigate("/login", { state: payload })
            return
        }

        try {
            // console.log("Checking activity availability...");
            const response = await checkActivityAvailability(
                item._id,
                infants + adults,
                date
            );
            // console.log("Activity availability response:", response);

            if (response) {
                // console.log("Navigating to secure checkout...");
                navigate("/securecheckout", {
                    state: {
                        selectedActivity: item,
                        selectedOption: item.options[selectedOption],
                        adults: adults,
                        infants: infants,
                        date: date,
                        selectedTime: selectedTime,
                        activityId: item._id,
                    },
                });
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            message.error(error.message);
        }
    };

    const getExcludedDateIntervals = () => {
        const excludedDates = [];
        const numDaysToExclude = 500; // Change this value as per your requirement

        const date = new Date(item.lastBookingDate);

        for (let i = 0; i < numDaysToExclude; i++) {
            const excludedStartDate = addDays(date, i + 1);
            const excludedEndDate = addDays(excludedStartDate, 1);
            excludedDates.push({ start: excludedStartDate, end: excludedEndDate });
        }

        return excludedDates;
    };

    const handleBack = (e) => {
        e.preventDefault();
        // console.log(selectedImageIndex);
        if (selectedImageIndex > 0) {
            setSelectedImageIndex((prev) => prev - 1);
        }
    };

    const handleNext = (e) => {
        e.preventDefault();
        // console.log(selectedImageIndex);
        if (selectedImageIndex < item?.images?.length - 1) {
            setSelectedImageIndex((prev) => prev + 1);
        }
    };




    // const handleCheckboxChange = (roomType) => {
    //     setSelectedRoomPackage(roomType);
    // };

    return (
        <div className='w-full font-nunito mt-2 px-20 max-sm:px-5 max-lg:px-5'>
            <div className='w-full grid grid-cols-3 p-2 gap-5 max-sm:grid-cols-1 border-b-2 border-gray-400 border-opacity-50 pb-5 max-lg:grid-cols-1'>
                <div className='w-full flex gap-3 max-sm:flex-col-reverse col-span-2'>
                    <div className='w-[20%] flex flex-col max-sm:flex-row max-sm:w-full gap-3 max-sm:hidden'>
                        {item?.images?.map((img, indx) => {
                            return (

                                <div className="flex gap-1" key={indx}>
                                    <img
                                        src={img}
                                        className={
                                            "md:img-choice w-full h-32 object-cover rounded-md cursor-pointer" +
                                            (selectedImageIndex === indx
                                                ? "border-2  border-orange-500rounded-lg"
                                                : "")
                                        }
                                        onClick={() => setSelectedImageIndex(indx)}
                                        alt="images"
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className='w-[80%] max-sm:w-full relative'>
                        <img src={item?.images[selectedImageIndex]} alt='logo' className="w-full h-[550px] rounded-lg object-cover object-center opacity-90 max-sm:h-72" />
                        <div className='flex w-full justify-between items-center px-10 absolute top-60 max-sm:top-32'>
                            <p className='p-2 rounded-full border-2 border-white text-whitesmoke text-center flex justify-center items-center cursor-pointer' onClick={handleBack}><ArrowBackIosIcon className='text-center pl-2 ' /></p>
                            <p className='p-2 rounded-full border-2 border-white text-whitesmoke text-center flex justify-center items-center cursor-pointer' onClick={handleNext}><ArrowForwardIosIcon className='text-center pr-1 ' /></p>
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-center items-center max-sm:mt-5'>

                    <form className='shadow-xl rounded-xl p-2 w-full max-sm:w-full' onSubmit={handleBookingSubmit}>
                        <div className='w-full text-lg'>
                            <h1 className='text-[20px] text-start ml-1 mt-2'>Check Availability</h1>
                        </div>
                        <div className="w-full flex justify-between items-center mt-4 border-2 rounded-lg">
                            <div className="flex w-full  justify-between items-center border-2 shadow-xl p-2 rounded-lg relative">
                                <h2 className='max-sm:text-lg'>Date</h2>
                                <div className='px-5 w-full'>
                                    {/* <input type='date'  onChange={(e) => setDate(e.target.value)} className='w-full px-5' /> */}
                                </div>
                                <div className="w-full absolute">
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => setDate(date)}
                                        value={date}

                                    // excludeDateIntervals={getExcludedDateIntervals()}
                                    />

                                </div>
                            </div>
                            <div className="p-5 flex justify-center rounded-xl items-center gap-3 shadow-xl cursor-pointer" onClick={() => setAdultSelectionOpen(!adultselectionOpen)}>
                                <img src="/group3.svg" alt="adults" />
                                <p>{adults + infants}</p>
                            </div>
                        </div>
                        {/* <div> */}
                        <div className={`shadow-lg w-full border-gray-400 rounded mt-6 p-2 flex-col justify-center items-end border-2 ${adultselectionOpen ? "flex" : "hidden"}`}>
                            <h2>Adults</h2>
                            <div className="flex items-center">
                                <Button
                                    type="text"
                                    icon={<MinusOutlined />}
                                    onClick={handleAdultDecrease}
                                    disabled={adults <= 0}
                                />
                                <InputNumber
                                    className="mx-2"
                                    min={0}
                                    value={adults}
                                    onChange={setAdults}
                                />
                                <Button
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={handleAdultIncrease}
                                />
                            </div>
                            <h2>Infants</h2>
                            <div className="flex items-center">
                                <Button
                                    type="text"
                                    icon={<MinusOutlined />}
                                    onClick={handleInfantDecrease}
                                    disabled={infants <= 0}
                                />
                                <InputNumber
                                    className="mx-2"
                                    min={0}
                                    value={infants}
                                    onChange={setInfant}
                                />
                                <Button
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={handleInfantIncrease}
                                />
                            </div>
                        </div>

                        <div className='w-full flex justify-start items-start my-2 mt-5'>
                            <p className='ml-4'>{item ? Object.keys(item.options).length : 0} options are available</p>
                        </div>

                        <div className='px-3 border-2  flex justify-start items-center flex-col gap-3 max-sm:w-full max-sm:text-xs max-lg:w-full'>
                            {Object.keys(item?.options).map((i) => {
                                const option = item.options[i];
                                const isSelectedOption = selectedOption === i;
                                return (
                                    // <label key={i}>
                                    <div key={i}
                                        className={
                                            isSelectedOption
                                                ? "option-card selected border-2 border-blue-500"
                                                : "option-card"
                                        }
                                    >
                                        <div className="flex justify-between items-center w-full flex-row">
                                            {/* <h2>{option.name}</h2> */}

                                            <p className="font-semibold text-xl font-nunito text-start">{option.name}</p>
                                            <input
                                                className="ml-16 md:ml-6"
                                                type="checkbox"
                                                value={i}
                                                checked={isSelectedOption}
                                                onChange={handleCheckboxChange}
                                            />
                                        </div>
                                        <p className='text-start'>{option.description}</p>
                                        <span>
                                            {adults} adult & {infants} infants
                                        </span>
                                        <p className="flex w-full justify-between items-center">

                                            <p> Total of:{" "}</p>
                                            <div className="flex gap-2 justify-center items-center">
                                                <h2 className="text-bold">
                                                    ₹ {(option?.unitPrice * (adults + infants)).toFixed(2)}
                                                </h2>
                                                {
                                                    option.realPrice && <del className="font-bold text-red-500">
                                                        ₹ {(option?.realPrice * (adults + infants)).toFixed(2)}
                                                    </del>
                                                }
                                            </div>
                                        </p>
                                        <span className="float-end text-end w-full">(Including all taxes and booking fees)</span>
                                        <br />
                                        <div className="option-card-time-options flex gap-2">
                                            {option.time.map((t, indx) => {
                                                const isSelectedTime =
                                                    isSelectedOption && selectedTime === t;
                                                const className = isSelectedTime
                                                    ? "time-option selected"
                                                    : "time-option";
                                                return (
                                                    <span
                                                        key={indx}
                                                        className={className}
                                                        onClick={() =>
                                                            isSelectedOption && setSelectedTime(t)
                                                        }
                                                    >
                                                        {t}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    // </label>
                                );
                            })}



                            {/* <div className="flex flex-col w-[90%] border-2 border-[#585C63] mt-10 p-5 rounded-lg shadow-lg max-sm:mt-5">
                            <div className="flex justify-between items-center">
                                <h1 className="font-semibold text-lg max-sm:text-sm">Standard Deluxe Room</h1>
                                <input type="checkbox" name="check-box" id="check-box" className="text-2xl" checked={selectedRoomPackage === 'standardDeluxe'} onChange={() => handleCheckboxChange('standardDeluxe')} />
                            </div>
                            <div>
                                <h1 className="font-light">Include: 5 Star Hotels + Breakfast Private Car with Driver + Pickup included</h1>
                                <p className="font-light">3 Adults</p>
                                <p className="font-semibold">26000.00 <span className="text-gray-500 font-normal">Per Night</span></p>
                                <p className="font-light">( Include all taxes and booking fees )</p>
                            </div>

                        </div> */}
                            <div className="flex flex-col w-full border-2 rounded-lg gap-3 m-0">
                                <button type="submit" className="bg-blue-700 w-full h-10 text-white py-1 px-2 rounded disabled:cursor-not-allowed disabled:bg-blue-300" disabled={!selectedOption}  >Book Now</button>
                                <button
                                    type="submit"
                                    className="w-full bg-red-500 text-white rounded h-10 disabled:bg-red-300 disabled:cursor-not-allowed"
                                    disabled={!selectedOption}
                                >
                                    Send as a Gift
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>



            <div className='w-full grid grid-cols-2 gap-3 px-5 py-10 max-sm:grid-cols-1 max-sm:px-2 max-lg:grid-cols-1'>
                <div className='w-full h-full'>
                    {item?.location?.url?.startsWith("https") && <LocationMap item={item} />}
                </div>
                <div className='w-full'>
                    <ReviewComponent token={token} activityId={item._id} user={user} />
                </div>
            </div>

            <div className='w-full'>

                {item?.overview && <ActivityDetail response={item} />}
            </div>


            <div className='w-full max-sm:py-3'>
                <SimilarActivity item={item} />
            </div>


        </div>
    )
}

export default SelectedBody